import React from 'react';
import { Link } from 'react-router-dom';
import { UtilService } from '../../utils/UtilService';

function PackageCard(props) {
  const { history, item } = props;
  return (
    <div key={item.id} className="col-lg-4 col-md-6 col-sm-6">
      <div className="room-box mb-30">
        <div className="room-img-wrap">
          <div
            onClick={() => {
              history.push({
                pathname: '/package-details',
                state: item.id
              });
            }}
            className="room-img"
            style={{
              backgroundImage: `url(${UtilService.encodeImageURI(
                item.titleImage
              )})`
            }}
          />
        </div>
        <div className="room-desc">
          <h4 className="title">{item.title}</h4>
          <p>{item.description}</p>
          <Link
            to={{
              pathname: '/package-details',
              state: item.id
            }}
            className="book-btn"
          >
            {'Mehr anzeigen'.toUpperCase()}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PackageCard;
