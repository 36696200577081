import { ApiCall } from '../apiBase';

/********get all bookings query********/
export async function getBookings() {
  let bookings = [];

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
        query getBookings {
          getBookings {
            id
            reservedDate
            startTime
            endTime
            numOfPacks
            status
            totalPrice
            reserverName
            reserverEmail
            reserverTelephone
            createdAt
            paymentId
            package {
              id
            }
          }
        }
      `
    }
  });

  if (!result.data.errors && result.data.data.getBookings) {
    bookings = result.data.data.getBookings;
  }

  return bookings;
}

/********get a Booking by it's id query********/
export async function getBookingById(bookingId) {
  let bookingRes = null;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
        query getBookingById($id: ID!) {
          getBooking(id: $id) {
            id
            reservedDate
            startTime
            endTime
            numOfPacks
            status
            totalPrice
            reserverName
            reserverEmail
            reserverTelephone
            createdAt
            paymentId
            package {
              id
            }
          }
        }
      `,
      variables: {
        id: bookingId
      }
    }
  });

  if (!result.data.errors && result.data.data.getBooking) {
    bookingRes = result.data.data.getBooking;
  }

  return bookingRes;
}

/*******check package availability********/
export async function checkAvailability(startTime, endTime) {
  let availabilityRes = null;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
      query checkAvailability($startTime: DateTime!, $endTime: DateTime!){
        checkAvailability(startTime: $startTime, endTime: $endTime) {
            isAvailable
            alternatives{
              date
            }
          }
        }`,
      variables: {
        startTime: startTime,
        endTime: endTime
      }
    }
  });

  if (!result.data.errors && result.data.data.checkAvailability) {
    availabilityRes = result.data.data.checkAvailability;
  }

  return availabilityRes;
}

export async function getS3URLbyEntityId(entityId, type) {
  let url = null;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
        query getS3URLbyEntityId($entityId: ID!,$type: EntityType! ) {
          getS3URLbyEntityId(entityId: $entityId, type: $type ) {
            s3URL
          }
        }
      `,
      variables: {
        entityId: entityId,
        type: type
      }
    }
  });

  if (!result.data.errors && result.data.data.getS3URLbyEntityId) {
    url = result.data.data.getS3URLbyEntityId;
  }

  return url;
}

/********add a booking mutation********/
export async function addBooking(newBooking) {
  let addBookingRes = null;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
            mutation addBooking($input: BookingInput!) {
              addBooking(input: $input) {
                id
              }
            }
          `,
      variables: {
        input: {
          bookingType: newBooking.bookingType,
          startTime: newBooking.startTime,
          endTime: newBooking.endTime,
          numOfPacks: newBooking.numOfPacks,
          subPackage: newBooking.subPackage,
          extras: newBooking.extras,
          totalPrice: newBooking.totalPrice,
          reserverName: newBooking.reserverName,
          reserverEmail: newBooking.reserverEmail,
          reserverTelephone: newBooking.reserverTelephone,
          bookingNote: newBooking.bookingNote,
          reserverAddress: newBooking.reserverAddress,
          voucherId: newBooking.voucherId,
          companyName: newBooking.reserverCompanyName
        }
      }
    }
  });

  if (!result.data.errors && result.data.data.addBooking) {
    addBookingRes = result.data.data.addBooking;
  }

  return addBookingRes;
}

/********update a package mutation********/
export async function updateBooking(updatedBooking) {
  let updateBookingRes = null;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
        mutation updateBooking($id: ID!, $input: BookingInput!) {
          updateBooking(id: $id, input: $input) {
            id
          }
        }
      `,
      variables: {
        id: updatedBooking.id,
        input: {
          reservedDate: updatedBooking.reservedDate,
          startTime: updatedBooking.startTime,
          endTime: updatedBooking.endTime,
          numOfPacks: updatedBooking.numOfPacks,
          package: updatedBooking.package,
          status: updatedBooking.status,
          totalPrice: updatedBooking.totalPrice,
          reserverName: updatedBooking.reserverName,
          reserverEmail: updatedBooking.reserverEmail,
          reserverTelephone: updatedBooking.reserverTelephone
        }
      }
    }
  });

  if (!result.data.errors && result.data.data.updateBooking) {
    updateBookingRes = result.data.data.updateBooking;
  }

  return updateBookingRes;
}

/********send booking pending email********/
export async function sendInvoicePendingEmail(bookingId) {
  let sendBookingEmail = null;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
        mutation sendInvoicePendingEmail($bookingId: ID!) {
          sendInvoicePendingEmail(bookingId: $bookingId) {
            message
            status
            downloadURL
          }
        }
      `,
      variables: {
        bookingId: bookingId
      }
    }
  });

  if (!result.data.errors && result.data.data.sendInvoicePendingEmail) {
    sendBookingEmail = result.data.data.sendInvoicePendingEmail;
  }

  return sendBookingEmail;
}

/********send booking success email********/
export async function sendBookingSuccessEmail(bookingId) {
  let sendBookingEmail = null;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
        mutation sendBookingSuccessEmail($bookingId: ID!) {
          sendBookingSuccessEmail(bookingId: $bookingId) {
            message
            status
          }
        }
      `,
      variables: {
        bookingId: bookingId
      }
    }
  });

  if (!result.data.errors && result.data.data.sendBookingSuccessEmail) {
    sendBookingEmail = result.data.data.sendBookingSuccessEmail;
  }

  return sendBookingEmail;
}

/********send booking failure email********/
export async function sendBookingFailureEmail(bookingId) {
  let sendBookingEmailFailure = null;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
        mutation sendBookingFailureEmail($bookingId: ID!) {
          sendBookingFailureEmail(bookingId: $bookingId) {
            message
            status
          }
        }
      `,
      variables: {
        bookingId: bookingId
      }
    }
  });

  if (!result.data.errors && result.data.data.sendBookingFailureEmail) {
    sendBookingEmailFailure = result.data.data.sendBookingFailureEmail;
  }

  return sendBookingEmailFailure;
}

/********delete a Booking mutation********/
export async function deleteBooking(bookingId) {
  let deleteBookingRes = null;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
          mutation deleteBooking($id: ID!) {
            deleteBooking(id: $id) {
                id
                reservedDate
                startTime
                endTime
                numOfPacks
                status
                totalPrice
                reserverName
                reserverEmail
                reserverTelephone
                createdAt
                paymentId
                package {
                  id
                }
            }
          }
        `,
      variables: {
        id: bookingId
      }
    }
  });

  if (!result.data.errors && result.data.data.deleteBooking) {
    deleteBookingRes = result.data.data.deleteBooking;
  }

  return deleteBookingRes;
}
