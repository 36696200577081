import React, { Component } from 'react';
import Slider from 'react-slick';

class Instagram extends Component {
  render() {
    const { slider_images, slider_URL } = this.props;
    const settings = {
      slidesToShow: 5,
      slidesToScroll: 1,
      fade: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      arrows: false,
      dots: false,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    };
    return (
      <section className="instagram-feed-section">
        <div className="container-fluid p-0">
          <Slider className="instagram-slider" {...settings}>
            {slider_images.map((img, i) => {
              return (
                <div key={i} className="image">
                  <a
                    href={`${slider_URL[i]}`}
                    target="_blank"
                    className="insta-popup"
                  >
                    <img src={img} alt="" />
                  </a>
                </div>
              );
            })}
          </Slider>
        </div>
      </section>
    );
  }
}

export default Instagram;
