import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { withRouter } from 'react-router-dom';
import { getPackages } from '../../api/services/package.service';
import { UtilService } from '../../utils/UtilService';

function SampleNextArrow(props) {
  const { onClick, className } = props;
  return (
    <div className={`slick-arrow next-arrow ${className}`} onClick={onClick}>
      <i className="fal fa-arrow-right"></i>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className={`slick-arrow prev-arrow `} onClick={onClick}>
      <i className="fal fa-arrow-left"></i>
    </div>
  );
}
class FeaturePackages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      packages: []
    };
  }

  componentDidMount() {
    getPackages().then(packages => {
      this.setState({
        packages
      });
    });
  }

  next = () => {
    this.slider.slickNext();
  };

  previous = () => {
    this.slider.slickPrev();
  };

  render() {
    const { packages } = this.state;
    const { history } = this.props;
    const settings = {
      slidesToShow: 3,
      slidesToScroll: 1,
      fade: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      dots: false,
      arrows: false,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };
    return (
      <section className="feature-room-section pt-155 pb-115">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-8 col-sm-7">
              <div className="section-title">
                <h2>Themen</h2>
              </div>
            </div>
            <div className="col-lg-6 col-md-4 col-sm-5 d-none d-sm-block">
              <div className="feature-room-arrow arrow-style text-right">
                <SamplePrevArrow onClick={this.previous} />
                <SampleNextArrow onClick={this.next} />
              </div>
            </div>
          </div>
          {/* Room Looop */}
          <Slider
            className="row room-gird-loop mt-80 feature-room-slider"
            {...settings}
            ref={c => (this.slider = c)}
          >
            {packages.map((item, i) => (
              <div
                key={item}
                className="col-lg-12"
                style={{ cursor: 'pointer' }}
              >
                <div className="room-box">
                  <div className="room-img-wrap">
                    <div
                      onClick={() => {
                        history.push({
                          pathname: '/package-details',
                          state: item.id
                        });
                      }}
                      className="room-img"
                      style={{
                        cursor: 'pointer',
                        backgroundImage:
                          'url(' +
                          UtilService.encodeImageURI(item.titleImage) +
                          ')'
                      }}
                    />
                  </div>
                  <div className="room-desc" style={{ cursor: 'pointer' }}>
                    <h4 className="title">
                      <Link
                        to={{ pathname: '/package-details', state: item.id }}
                      >
                        {item.title}
                      </Link>
                    </h4>
                    <p>{item.description}</p>
                    <Link
                      to={{ pathname: '/package-details', state: item.id }}
                      className="book-btn"
                    >
                      {'Mehr Anzeigen'.toUpperCase()}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    );
  }
}

export default withRouter(FeaturePackages);
