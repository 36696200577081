import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { findDOMNode } from 'react-dom';

const navigationmenu = [
  {
    id: 1,
    link: '/',
    linkText: 'Home',
    child: false
  },
  {
    id: 2,
    link: '/packages',
    linkText: 'Themen',
    child: false
  },
  {
    id: 3,
    link: '/vouchers',
    linkText: 'Gutschein',
    child: false
  },
  {
    id: 4,
    link: '/cafeteria',
    linkText: 'Donaucafe',
    child: false
  },
  {
    id: 5,
    link: '/gallery',
    linkText: 'Galerie',
    child: false
  }
];

class Headertwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redText: false,
      isMobile: window.innerWidth < 1020
    };
    this.addClass = this.addClass.bind(this);
    this.removeClass = this.removeClass.bind(this);
    this.removeAll = this.removeAll.bind(this);
  }
  addClass() {
    this.setState({
      redText: true
    });
  }

  removeClass() {
    this.setState({
      redText: false
    });
  }
  removeAll() {
    this.setState({
      redText: false
    });
  }
  componentDidMount() {
    window.addEventListener(
      'resize',
      () => {
        this.setState({
          isMobile: window.innerWidth < 1020
        });
      },
      false
    );
    window.addEventListener(
      'load',
      () => {
        this.setState({
          isMobile: window.innerWidth < 1020
        });
      },
      false
    );
    window.addEventListener(
      'scroll',
      () => {
        this.setState({
          isTop: window.scrollY > 150
        });
      },
      false
    );
  }
  navToggle = () => {
    const nv = findDOMNode(this.refs.navmenu);
    const nvb = findDOMNode(this.refs.navbtn);
    $(nv).toggleClass('menu-on');
    $(nvb).toggleClass('active');
  };
  removenavToggle = () => {
    const nv = findDOMNode(this.refs.navmenu);
    const nvb = findDOMNode(this.refs.navbtn);
    $(nv).removeClass('menu-on');
    $(nvb).removeClass('active');
  };
  getNextSibling = function (elem, selector) {
    // Get the next sibling element
    var sibling = elem.nextElementSibling;

    // If there's no selector, return the first sibling
    if (!selector) return sibling;

    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
      if (sibling.matches(selector)) return sibling;
      sibling = sibling.nextElementSibling;
    }
  };

  triggerChild = e => {
    let subMenu = '';

    subMenu =
      this.getNextSibling(e.target, '.submenu') !== undefined
        ? this.getNextSibling(e.target, '.submenu')
        : null;

    if (subMenu !== null && subMenu !== undefined && subMenu !== '') {
      subMenu.classList = subMenu.classList.contains('open')
        ? 'submenu'
        : 'submenu open';
    }
  };
  render() {
    const className = this.state.isMobile ? 'breakpoint-on' : '';
    const classNamess = this.state.isMobile ? 'd-none' : '';
    const classNamesss = this.state.isTop ? 'sticky-active' : '';

    return (
      <div>
        {/*====== HEADER START ======*/}
        <header
          className={`header-absolute header-two sticky-header ${classNamesss}`}
        >
          <div className="container container-custom-one">
            <div
              className={`nav-container d-flex align-items-center justify-content-between ${className}`}
            >
              {/* Main Menu */}
              <div
                className="nav-menu d-lg-flex align-items-center"
                ref="navmenu"
              >
                {/* Navbar Close Icon */}
                <div className="navbar-close" onClick={this.removenavToggle}>
                  <div className="cross-wrap">
                    <span className="top" />
                    <span className="bottom" />
                  </div>
                </div>
                {/* Off canvas Menu  */}
                <div className="toggle" onClick={this.addClass}>
                  {/* <Link to="#" id="offCanvasBtn">
                    <i className="fal fa-bars" />
                  </Link> */}
                </div>
                {/* Mneu Items */}
                <div className="menu-items">
                  <ul>
                    {navigationmenu.length > 0
                      ? navigationmenu.map((item, i) => (
                          <li
                            key={i}
                            className={` ${
                              item.child ? 'menu-item-has-children' : ''
                            } `}
                            onClick={this.triggerChild}
                          >
                            {item.child ? (
                              <Link onClick={e => e.preventDefault()} to="/">
                                {' '}
                                {item.linkText}{' '}
                              </Link>
                            ) : (
                              <Link to={item.link}> {item.linkText} </Link>
                            )}
                            {item.child ? (
                              <ul className="submenu" role="menu">
                                {item.submenu.map((sub_item, i) => (
                                  <li
                                    key={i}
                                    className={`${
                                      sub_item.child
                                        ? 'menu-item-has-child'
                                        : ''
                                    } `}
                                  >
                                    {sub_item.child ? (
                                      <Link
                                        onClick={e => e.preventDefault()}
                                        to="/"
                                      >
                                        {' '}
                                        {sub_item.linkText}{' '}
                                      </Link>
                                    ) : (
                                      <Link to={sub_item.link}>
                                        {' '}
                                        {sub_item.linkText}{' '}
                                      </Link>
                                    )}
                                    {sub_item.third_menu ? (
                                      <ul className="submenu">
                                        {sub_item.third_menu.map(
                                          (third_item, i) => (
                                            <li key={i}>
                                              <Link to={third_item.link}>
                                                {third_item.linkText}
                                              </Link>
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    ) : null}
                                  </li>
                                ))}
                              </ul>
                            ) : null}
                          </li>
                        ))
                      : null}
                  </ul>
                </div>
                {/* from pushed-item */}
                <div className="nav-pushed-item" />
              </div>
              {/* Site Logo */}
              <div className="site-logo">
                <Link to="/" className="main-logo">
                  <img src="assets/img/ms-logo.png" alt="" />
                </Link>
                <Link to="/" className="sticky-logo">
                  <img src="assets/img/ms-logo.png" alt="" />
                </Link>
              </div>
              {/* Header Info Pussed To Menu Wrap */}
              <div className={`nav-push-item ${classNamess}`}>
                {/* Header Info */}
                <div className="header-info d-lg-flex align-items-center">
                  <div className="item">
                    <i className="fal fa-phone" />
                    <span>Telefon</span>
                    <div>
                      <h6 className="title">+43 660 66 88 333</h6>
                    </div>
                  </div>
                  <div className="item">
                    <i className="fal fa-envelope" />
                    <span>Email</span>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="mailto:office@ms-sissi.at"
                    >
                      <h6 className="title">office@ms-sissi.at</h6>
                    </a>
                  </div>
                </div>
              </div>
              {/* Navbar Toggler */}
              <div
                className="navbar-toggler"
                onClick={this.navToggle}
                ref="navbtn"
              >
                <span />
                <span />
                <span />
              </div>
            </div>
          </div>
        </header>
        {/*====== HEADER END ======*/}
        {/*====== OFF CANVAS START ======*/}
        {/* <div
          className={classNames('offcanvas-wrapper', {
            'show-offcanvas': this.state.redText
          })}
        >
          <div
            className={classNames('offcanvas-overly', {
              'show-overly': this.state.redText
            })}
            onClick={this.removeAll}
          />
          <div className="offcanvas-widget">
            <Link to="#" className="offcanvas-close" onClick={this.removeClass}>
              <i className="fal fa-times" />
            </Link>
            <div className="widget about-widget">
              <h5 className="widget-title">About us</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia
                reiciendis illo ipsa asperiores, perspiciatis corrupti veritatis
                assumenda architecto commodi provident quas necessitatibus
                consequatur praesentium magnam optio deserunt fugiat repellat
                culpa.
              </p>
            </div>
            <div className="widget nav-widget">
              <h5 className="widget-title">Our pages</h5>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/packages">Packages</Link>
                </li>
                <li>
                  <Link to="/vouchers">Vouchers</Link>
                </li>
                <li>
                  <Link to="/cafeteria">Cafeteria</Link>
                </li>
                <li>
                  <Link to="/extras">Extras</Link>
                </li>
                <li>
                  <Link to="/gallery">Gallery</Link>
                </li>
              </ul>
            </div>
            <div className="widget social-link">
              <h5 className="widget-title">Contact with us</h5>
              <ul>
                <li>
                  <Link to="https://www.facebook.com/ReedereiRichtfeld/">
                    <i className="fab fa-facebook-f" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fab fa-instagram" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
        {/*====== OFF CANVAS END ======*/}
      </div>
    );
  }
}

export default Headertwo;
