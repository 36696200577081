import { ApiCall } from '../apiBase';

/********get all vouchers query********/
export async function getAllVouchers() {
  let vouchers = [];

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
        query getAllVouchers {
          getAllVouchers {
            id
            code
            value
            status
            purchaserName
            purchaserEmail
            expireDate
            createdAt
            paymentId
          }
        }
      `
    }
  });

  if (!result.data.errors && result.data.data.getAllVouchers) {
    vouchers = result.data.data.getAllVouchers;
  }

  return vouchers;
}

/********check voucher validation query********/
export async function checkVoucherValidation(voucherCode) {
  let voucherValidres = null;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
        query checkVoucherValidation($code: String!) {
          checkVoucherValidation(code: $code) {
            valid
            message
            status
            amount
            id
          }
        }
      `,
      variables: {
        code: voucherCode
      }
    }
  });

  if (!result.data.errors && result.data.data.checkVoucherValidation) {
    voucherValidres = result.data.data.checkVoucherValidation;
  }

  return voucherValidres;
}

/********get a voucher by it's id query********/
export async function getVoucherById(voucherId) {
  let voucherRes = null;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
        query getVoucherById($id: ID!) {
          getVoucher(id: $id) {
            id
            code
            value
            status
            purchaserName
            purchaserEmail
            expireDate
            createdAt
            paymentId
          }
        }
      `,
      variables: {
        id: voucherId
      }
    }
  });

  if (!result.data.errors && result.data.data.getVoucher) {
    voucherRes = result.data.data.getVoucher;
  }

  return voucherRes;
}

/********get a voucher by it's code query********/
export async function getVoucherByCode(voucherCode) {
  let voucherRes = null;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
        query getVoucherByCode($code: String!) {
          getVoucherByCode(code: $code) {
            id
            code
            value
            status
            purchaserName
            purchaserEmail
            expireDate
            createdAt
            paymentId
          }
        }
      `,
      variables: {
        code: voucherCode
      }
    }
  });

  if (!result.data.errors && result.data.data.getVoucherByCode) {
    voucherRes = result.data.data.getVoucherByCode;
  }

  return voucherRes;
}

/********add a voucher mutation********/
export async function addVoucher(newVoucher) {
  let addVoucherRes = null;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
          mutation addVoucher($input: VoucherInput!) {
            addVoucher(input: $input) {
                id
                code
                value
                status
                purchaserName
                purchaserEmail
                expireDate
                createdAt
                paymentId
            }
          }
        `,
      variables: {
        input: {
          value: newVoucher.voucherValue,
          purchaserEmail: newVoucher.voucherPurchaserEmail,
          purchaserName: newVoucher.voucherPurchaserName,
          paymentId: newVoucher.voucherPaymetId,
          purchaserAddress: newVoucher.purchaserAddress
        }
      }
    }
  });

  if (!result.data.errors && result.data.data.addVoucher) {
    addVoucherRes = result.data.data.addVoucher;
  }

  return addVoucherRes;
}

/********update a voucher mutation********/
export async function updateVoucher(voucherId, voucherStatus) {
  let updateVoucherRes = null;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
        mutation updateVoucher($id: ID!, $status: Statuses!) {
          updateVoucher(id: $id, status: $status) {
            id
            code
            value
            status
            purchaserName
            purchaserEmail
            expireDate
            createdAt
            paymentId
          }
        }
      `,
      variables: {
        id: voucherId,
        status: voucherStatus
      }
    }
  });

  if (!result.data.errors && result.data.data.updateVoucher) {
    updateVoucherRes = result.data.data.updateVoucher;
  }

  return updateVoucherRes;
}

/********delete a voucher mutation********/
export async function deleteVoucher(voucherId) {
  let deleteVoucherRes = null;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
          mutation deleteVoucher($id: ID!) {
            deleteVoucher(id: $id) {
              id
              code
              value
              status
              purchaserName
              purchaserEmail
              expireDate
              createdAt
              paymentId
            }
          }
        `,
      variables: {
        id: voucherId
      }
    }
  });

  if (!result.data.errors && result.data.data.deleteVoucher) {
    deleteVoucherRes = result.data.data.deleteVoucher;
  }

  return deleteVoucherRes;
}

/********send voucher pending email********/
export async function sendVoucherInvoicePendingEmail(voucherId) {
  let sendVoucherEmail = null;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
        mutation sendVoucherInvoicePendingEmail($voucherId: ID!) {
          sendVoucherInvoicePendingEmail(voucherId: $voucherId) {
            message
            status
            downloadURL
          }
        }
      `,
      variables: {
        voucherId: voucherId
      }
    }
  });

  if (!result.data.errors && result.data.data.sendVoucherInvoicePendingEmail) {
    sendVoucherEmail = result.data.data.sendVoucherInvoicePendingEmail;
  }

  return sendVoucherEmail;
}

/********send voucher success email********/
export async function sendVoucherSuccessEmail(voucherId) {
  let sendVoucherEmail = null;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
        mutation sendVoucherSuccessEmail($voucherId: ID!) {
          sendVoucherSuccessEmail(voucherId: $voucherId) {
            message
            status
          }
        }
      `,
      variables: {
        voucherId: voucherId
      }
    }
  });

  if (!result.data.errors && result.data.data.sendVoucherSuccessEmail) {
    sendVoucherEmail = result.data.data.sendVoucherSuccessEmail;
  }

  return sendVoucherEmail;
}

/********send voucher failure email********/
export async function sendVoucherFailureEmail(voucherId) {
  let sendVoucherEmailFail = null;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
        mutation sendVoucherFailureEmail($voucherId: ID!) {
          sendVoucherFailureEmail(voucherId: $voucherId) {
            message
            status
          }
        }
      `,
      variables: {
        voucherId: voucherId
      }
    }
  });

  if (!result.data.errors && result.data.data.sendVoucherFailureEmail) {
    sendVoucherEmailFail = result.data.data.sendVoucherFailureEmail;
  }

  return sendVoucherEmailFail;
}
