import React, { Component } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import ReactWOW from 'react-wow';

class Textblock extends Component {
  state = {
    open: false
  };
  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };
  render() {
    const { open } = this.state;
    const { danube_title, danube_description, danube_videolink } = this.props;

    return (
      <section
        className="text-block with-bg pt-115 pb-115"
        style={{ backgroundImage: 'url(assets/img/bg/02.jpg)' }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-center justify-content-lg-between">
            <ReactWOW animation="fadeInLeft" data-wow-delay=".3s">
              <div className="col-lg-5 col-md-8 col-sm-10">
                <div className="block-text mb-small">
                  <div className="section-title mb-20">
                    <span className="title-tag">Die Donau</span>
                    <h2>{danube_title}</h2>
                  </div>
                  <p>{danube_description}</p>
                </div>
              </div>
            </ReactWOW>
            <ReactWOW animation="fadeInRight" data-wow-delay=".5s">
              <div className="col-lg-6 col-md-10">
                <div
                  className="video-wrap"
                  style={{
                    backgroundImage:
                      'url(https://s3.eu-central-1.amazonaws.com/ms-sissi.uploads/cms/ship2.gif)'
                  }}
                >
                  <div onClick={this.onOpenModal} className="popup-video">
                    <i className="fas fa-play" />
                  </div>
                  <Modal
                    open={open}
                    onClose={this.onCloseModal}
                    center
                    classNames={{
                      modal: 'video-modal'
                    }}
                  >
                    <iframe
                      width="560"
                      height="315"
                      src={danube_videolink}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </Modal>
                </div>
              </div>
            </ReactWOW>
          </div>
        </div>
      </section>
    );
  }
}

export default Textblock;
