import React, { Component } from 'react';
import AltHeader from '../layouts/AltHeaderComponent';
import Footer from '../layouts/FooterComponent';

class ImprintPage extends Component {
  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  render() {
    return (
      <div>
        <AltHeader page_title="IMPRESSUM" router_link="Impressum"/>
        <section>
          <div className="container pt-100 pb-100">
            <p>Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63
            Gewerbeordnung und Offenlegungspflicht laut §25 Mediengesetz.</p>

            <div class="mt-50">
              Reederei Richtfeld - Peter Richtfeld <br />
              Unternehmensgegenstand: Binnenschifffahrt im Gelegenheitsverkehr <br />
              UID-Nummer: ATU66387545 <br />
              Firmensitz: Egon Schiele Straße 3, 4600 Wels <br />
              E-Mail: office@ms-sissi.at <br />
              Geschäftsführer: Thomas Freudenthaler
            </div>

            <div class="mt-50">
              <h6> HAFTUNG FÜR INHALTE DIESER WEBSEITE </h6>
              Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle
              Informationen bereitzustellen. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf
              dieser Webseite übernehmen, speziell für jene die seitens Dritter bereitgestellt wird. Sollten Ihnen
              problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu kontaktieren, Sie
              finden die Kontaktdaten im Impressum.
            </div>

            <div class="mt-50">
              <h6> HAFTUNG FÜR LINKS AUF DIESER WEBSEITE </h6>
              Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich sind.
              Wenn Ihnen rechtswidrige Links auf unserer Webseite auffallen, bitte wir Sie uns zu kontaktieren, Sie
              finden die Kontaktdaten im Impressum.
            </div>

            <div class="mt-50">
              <h6> URHEBERRECHTSHINWEIS </h6>
              Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Falls
              notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich
              verfolgen.
            </div>

            <div class="mt-50">
              <h6> BILDERNACHWEIS </h6>
              Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt. Die Bilderrechte
              liegen bei den folgenden Fotografen und Unternehmen:
              <br />
              Reederei Richtfeld - Peter Richtfeld <br />
              Shutterstock.com
            </div>


            <div class="mt-100">
              <h2> DATENSCHUTZERKLÄRUNG </h2>
            </div>

            <div class="mt-50">
              <h6> DATENSCHUTZ </h6>
              Wir haben diese Datenschutzerklärung verfasst, um Ihnen gemäß der Vorgaben der EU-DatenschutzGrundverordnung zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden und
              welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben. Leider liegt es in der
              Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben uns bei der Erstellung
              jedoch bemüht die wichtigsten Dinge so einfach und klar zu beschreiben.
            </div>

            <div class="mt-50">
              <h6> AUTOMATISCHE DATENSPEICHERUNG </h6>
              Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch erstellt und
              gespeichert, so auch auf dieser Webseite. Wenn Sie unsere Webseite so wie jetzt gerade besuchen,
              speichert unser Webserver (Computer auf dem diese Webseite gespeichert ist) automatisch Daten
              wie die IP-Adresse Ihres Gerätes, die Adressen der besuchten Unterseiten, Details zu Ihrem Browser
              (z.B. Chrome, Firefox, Edge,...) und Datum sowie Uhrzeit. Wir nutzen diese Daten nicht und geben Sie
              in der Regel nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von
              rechtswidrigem Verhalten eingesehen werden.
            </div>

            <div class="mt-50">
              <h6> SPEICHERUNG PERSÖNLICHER DATEN </h6>
              Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel Name,
              E-Mail-Adresse, Adresse oder andere persönlichen Angaben im Rahmen der Übermittlung eines
              Formulars oder Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt und der IPAdresse nur zum jeweils angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte
              weitergegeben. Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen
              Besuchern, die Kontakt ausdrücklich wünschen und für die Abwicklung der auf dieser Webseite
              angebotenen Dienstleistungen und Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung
              nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem
              Verhalten eingesehen werden. Wenn Sie uns persönliche Daten per E-Mail schicken - somit abseits
              dieser Webseite - können wir keine sichere Übertragung und den Schutz Ihrer Daten garantieren. Wir
              empfehlen Ihnen, vertrauliche Daten niemals unverschlüsselt per E-Mail zu übermitteln.
            </div>

            <div class="mt-50">
              <h6> IHRE RECHTE </h6>
              Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung,
              Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer
              Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in
              einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. In
              Österreich ist dies die Datenschutzbehörde, deren Webseiten Sie unter https://www.dsb.gv.at/
              finden.
            </div>

            <div class="mt-50">
              <h6> TLS-VERSCHLÜSSELUNG MIT HTTPS  </h6>
              Wir verwenden https um Daten abhörsicher im Internet zu übertragen. Durch den Einsatz von TLS
              (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im
              Internet können wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen die Benutzung dieser
              Absicherung der Datenübertragung am kleinen Schlosssymbol links oben im Browser und der
              Verwendung des Schemas https als Teil unserer Internetadresse.
            </div>

            <div class="mt-50">
              <h6> COOKIES  </h6>
              Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um kleine Textdateien, die
              mit Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an.
              <br /> <br />
              Wir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf
              Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim
              nächsten Besuch wiederzuerkennen.
              <br /> <br />
              Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten, dass er Sie über das
              Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben.
              <br /> <br />
              Bei der Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.
            </div>

            <div class="mt-50">
              <h6> GOOGLE MAPS DATENSCHUTZERKLÄRUNG  </h6>
              Wir verwenden Google Maps der Firma Google Inc. (1600 Amphitheatre Parkway Mountain View, CA
              94043, USA) auf unserer Webseite. Durch die Nutzung der Funktionen dieser Karte werden Daten an
              Google übertragen. Welche Daten von Google erfasst werden und wofür diese Daten verwendet
              werden, können Sie auf https://www.google.com/intl/de/policies/privacy/ nachlesen.
            </div>

            <div class="mt-50">
              <h6> FACEBOOK DATENSCHUTZERKLÄRUNG  </h6>
              Wir verwenden auf dieser Webseite Funktionen von Facebook, einem Social Media Network der
              FIrma Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2 Ireland. Welche
              Funktionen (Soziale Plug-ins) Facebook bereitstellt, können Sie auf
              https://developers.facebook.com/docs/plugins/ nachlesen. Durch den Besuch unserer Webseite
              können Informationen an Facebook übermittelt werden. Wenn Sie über ein Facebook-Konto
              verfügen, kann Facebook diese Daten Ihrem persönlichen Konto zuordnen. Sollten Sie das nicht
              wünschen, melden Sie sich bitte von Facebook ab. Die Datenschutzrichtlinien, welche Informationen
              Facebook sammelt und wie sie diese verwenden finden Sie auf
              https://www.facebook.com/policy.php.
            </div>

            <div class="mt-50">
              <h6> FACEBOOK-PIXEL DATENSCHUTZERKLÄRUNG   </h6>
              Wir verwenden auf dieser Webseite das Facebook Pixel von Facebook, einem Social Media Network
              der Firma Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2 Ireland. Der
              auf dieser Seite implementierte Code kann das Verhalten der Besucher auswerten, welche von einer
              Facebook-Werbung auf diese Webseite gelangt sind. Dies kann zur Verbesserung von FacebookWerbeanzeigen genutzt werden und diese Daten werden von Facebook erfasst und gespeichert. Die
              erfassten Daten sind für uns nicht einsehbar sondern nur im Rahmen von Werbeanzeigenschaltungen
              nutzbar. Durch den Einsatz des Facebook-Pixel-Codes werden auch Cookies gesetzt. Durch die
              Nutzung des Facebook-Pixels wird der Besuch dieser Webseite Facebook mitgeteilt, damit Besucher
              auf Facebook passende Anzeigen zu sehen bekommen. Wenn Sie ein Facebook-Konto besitzen und
              angemeldet sind, wird der Besuch dieser Webseite Ihrem Facebook-Benutzerkonto zugeordnet. Wie
              das Facebook-Pixel für Werbekampagnen genutzt wird, erfahren Sie auf
              https://www.facebook.com/business/learn/facebook-ads-pixel. Sie können Ihre Einstellungen für
              Werbeanzeigen in Facebook auf
              https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen verändern, sofern
              Sie in Facebook angemeldet sind. Auf
              http://www.youronlinechoices.com/de/praferenzmanagement/ können Sie Ihre Präferenzen
              hinsichtlich nutzungsbasierter Online-Werbung verwalten. Sie können dort viele Anbieter auf einmal
              deaktivieren oder aktivieren oder die Einstellungen für einzelne Anbieter vornehmen. Mehr
              Informationen zur Datenrichtlinie von Facebook finden Sie auf
              https://www.facebook.com/policy.php.
            </div>

            <div class="mt-50">
              <h6> COOKIES DEAKTIVIEREN  </h6>
              Sie können jederzeit Cookies, die sich bereits auf Ihrem Computer befinden, löschen oder Cookies
              deaktivieren. Die Vorgangsweise dazu ist nach Browser unterschiedlich, am besten Sie suchen die
              Anleitung in Google mit dem Suchbegriff “cookies löschen chrome” oder “cookies deaktivieren
              chrome” im Falle eines Chrome Browsers oder tauschen das Wort “chrome” gegen den Namen Ihres
              Browsers, z. B. edge, firefix, safari aus. Wenn Sie uns generell nicht gestatten, Cookies zu nutzen, d.h.
              diese per Browsereinstellung deaktivieren, können manche Funktionen und Seiten nicht wie
              erwartet funktionieren.
            </div>

            <div class="mt-50">
              <h6> PERSONALISIERTE WERBUNG DEAKTIVIEREN  </h6>
              Wenn Sie ein Google Konto haben, können Sie auf der Seite
              https://adssettings.google.com/authenticated personalisierte Werbung deaktivieren. Auch im Falle
              der Deaktivierung der Personalisierung von Anzeigen sehen Sie möglicherweise weiterhin Anzeigen
              auf der Grundlage von Faktoren wie Ihrem ungefähren Standort, der aus Ihrer IP-Adresse abgeleitet
              wird, dem Browsertyp sowie den kürzlich verwendeten Suchbegriffen. Welche Daten von Google
              erfasst werden und wofür diese Daten verwendet werden, können Sie auf
              https://www.google.com/intl/de/policies/privacy/ nachlesen.
            </div>

          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default ImprintPage;
