import { ApiCall } from '../apiBase';

/********get all sub packages query********/
export async function getSubPackages() {
  let subPackages = [];

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
        query getSubPackages {
          getSubPackages {
            id
            title
            titleImage
            description
            duration
            tripLength
            price
            packageId
            addOns {
              id
              title
              image
              description
              pricingType
              price
            }
          }
        }
      `
    }
  });

  if (!result.data.errors && result.data.data.getSubPackages) {
    subPackages = result.data.data.getSubPackages;
  }

  return subPackages;
}

/********get a sub package by it's id query********/
export async function getSubPackageById(subPackageId) {
  let subPackageRes = null;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
        query getSubPackageById($id: ID!) {
          getSubPackage(id: $id) {
            id
            title
            titleImage
            description
            duration
            tripLength
            price
            packageId
            addOns {
              id
              title
              image
              description
              pricingType
              price
            }
          }
        }
      `,
      variables: {
        id: subPackageId
      }
    }
  });

  if (!result.data.errors && result.data.data.getSubPackage) {
    subPackageRes = result.data.data.getSubPackage;
  }

  return subPackageRes;
}

/********get sub packages by it's id query********/
export async function getSubPackageByPackageId(packageId) {
  let packageRes = null;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
        query getSubPackageByPackageId($packageId: ID!) {
          getSubPackageByPackageId(packageId: $packageId) {
            id
            title
            titleImage
            description
            duration
            tripLength
            price
            addOns {
              id
              title
              image
              description
              pricingType
              price
            }
          }
        }
      `,
      variables: {
        packageId: packageId
      }
    }
  });

  if (!result.data.errors && result.data.data.getSubPackageByPackageId) {
    packageRes = result.data.data.getSubPackageByPackageId;
  }

  return packageRes;
}

/********add a sub packge mutation********/
export async function addSubPackage(newSubPackage) {
  let addSubPackageRes = null;
  let success = false;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
          mutation addSubPackage($input: SubPackageInput!) {
            addSubPackage(input: $input) {
              id
              title
              titleImage
              description
              duration
              tripLength
              price
              packageId
              addOns {
                id
                title
                image
                description
                pricingType
                price
              }
            }
          }
        `,
      variables: {
        input: {
          title: newSubPackage.subpackageTitle,
          titleImage: newSubPackage.subpackageTitleImageURL,
          description: newSubPackage.subpackageDescription,
          duration: newSubPackage.subpackageTimeDuration,
          tripLength: newSubPackage.subpackageTripLength,
          price: newSubPackage.subpackagePrice,
          packageId: newSubPackage.selectedPackageForSubpackage,
          addOns: newSubPackage.selectedExtrasForSubpackage
        }
      }
    }
  });

  if (!result.data.errors && result.data.data.addSubPackage) {
    addSubPackageRes = result.data.data.addSubPackage;
  }

  if (addSubPackageRes) {
    success = true;
  }

  return success;
}

/********update a sub packge mutation********/
export async function updateSubPackage(subpackageId, updatedSubPackage) {
  let updateSubPackageRes = null;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
        mutation updateSubPackage($id: ID!, $input: SubPackageInput!) {
          updateSubPackage(id: $id, input: $input) {
            id
            title
            titleImage
            description
            duration
            tripLength
            price
            packageId
            addOns {
              id
              title
              image
              description
              pricingType
              price
            }
          }
        }
      `,
      variables: {
        id: subpackageId,
        input: {
          title: updatedSubPackage.subpackageTitle,
          titleImage: updatedSubPackage.subpackageTitleImageURL,
          description: updatedSubPackage.subpackageDescription,
          duration: updatedSubPackage.subpackageTimeDuration,
          tripLength: updatedSubPackage.subpackageTripLength,
          price: updatedSubPackage.subpackagePrice,
          packageId: updatedSubPackage.selectedPackageForSubpackage,
          addOns: updatedSubPackage.selectedExtrasForSubpackage
        }
      }
    }
  });

  if (!result.data.errors && result.data.data.updateSubPackage) {
    updateSubPackageRes = result.data.data.updateSubPackage;
  }

  return updateSubPackageRes;
}

/********delete a sub package mutation********/
export async function deleteSubPackage(subPackageId) {
  let deleteSubPackageRes = null;
  let success = false;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
            mutation deleteSubPackage($id: ID!) {
              deleteSubPackage(id: $id) {
                id
                title
                titleImage
                description
                duration
                tripLength
                price
                packageId
                addOns {
                  id
                  title
                  image
                  description
                  pricingType
                  price
                }
              }
            }
          `,
      variables: {
        id: subPackageId
      }
    }
  });

  if (!result.data.errors && result.data.data.deleteSubPackage) {
    deleteSubPackageRes = result.data.data.deleteSubPackage;
  }

  if (deleteSubPackageRes) {
    success = true;
  }

  return success;
}
