import React, { Component } from 'react';
import moment from 'moment';
import { Row } from 'react-bootstrap';
// import { Row } from 'react-bootstrap';

class TimeDisplay extends Component {
  renderCafeOpeningHours = () => {
    const { openingHours } = this.props;

    return openingHours.map(h => (
      <div className="col-lg-6 col-12">
        <div className="counter-box box-layout d-flex justify-content-center justify-content-lg-center">
          <Row>
            <h4>{moment(h.startTime).format('HH:mm')}</h4>
            <span className="title">BIS</span>
            <h4>{moment(h.endTime).format('HH:mm')}</h4>
          </Row>
        </div>
      </div>
    ));
  };
  render() {
    const { openingHours, cafeCloseMsg } = this.props;
    const hasOpeningHours = openingHours.length > 0;
    return (
      <section className="counter-section">
        <br /> <br />
        <div className="container">
          <center>
            <div className="counter-inner bg-black pt-100 pb-60">
              <h2 className="label">
                {hasOpeningHours
                  ? 'Die heutigen Öffnungszeiten des Cafés'
                  : `${cafeCloseMsg}`}
              </h2>

              <div className="row justify-content-center">
                <div className="col-xl-10 col-11">
                  <div className="row justify-content-center">
                    {hasOpeningHours && this.renderCafeOpeningHours()}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="counter-inner bg-black pt-100 pb-60 pr-60 pl-60">
            <h2 className="label" style={{ textAlign: 'center' }}>
              Die Ms Sissi kann aufgrund der aktuellen <b>COVID</b> Situation
              derzeit leider nicht gebucht werden. Wir informieren Sie, wenn es
              wieder losgeht
            </h2>
          </div> */}
          </center>
        </div>
      </section>
    );
  }
}

export default TimeDisplay;
