import React, { Component } from 'react';
import Masonry from 'react-masonry-component';
import ReactWOW from 'react-wow';
import { UtilService } from '../../utils/UtilService';

class About extends Component {
  render() {
    const {
      card1_title,
      card1_description,
      card2_title,
      card2_description,
      card3_img,
      card4_img,
      aboutus_title,
      aboutus_description
    } = this.props;
    return (
      <section className="about-section pt-115 pb-115">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <ReactWOW animation="fadeInLeft" data-wow-delay=".3s">
              <div className="col-lg-6 col-md-10">
                <Masonry className="row about-features-boxes fetaure-masonary">
                  <div className="col-sm-6">
                    <div className="single-feature-box">
                      <div className="icon">
                        <i className="flaticon-team" />
                      </div>
                      <h4>
                        <div>{card1_title}</div>
                      </h4>
                      <p>{card1_description}</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className="single-feature-box only-bg mt-30"
                      style={{
                        backgroundImage: `url(${UtilService.encodeImageURI(
                          card3_img
                        )})`
                      }}
                    ></div>
                  </div>
                  <div className="col-sm-6">
                    <div className="single-feature-box dark mt-30">
                      <div className="icon">
                        {/* <i className="flaticon-hotel" /> */}
                        <img
                          src="assets/img/icon/Sissi_icon.png"
                          width="60%"
                          style={{ opacity: 0.8 }}
                          alt="hotel"
                        />
                      </div>
                      <h4>
                        <div>{card2_title}</div>
                      </h4>
                      <p>{card2_description}</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className="single-feature-box only-bg mt-30"
                      style={{
                        backgroundImage: `url(${UtilService.encodeImageURI(
                          card4_img
                        )})`
                      }}
                    ></div>
                  </div>
                </Masonry>
              </div>
            </ReactWOW>
            <ReactWOW animation="fadeInRight" data-wow-delay=".3s">
              <div className="col-lg-6 col-md-8 col-sm-10">
                <div className="abour-text pl-50 pr-50">
                  <div className="section-title mb-30">
                    <span className="title-tag">ÜBER UNS</span>
                    <h2>{aboutus_title}</h2>
                  </div>
                  <p>{aboutus_description}</p>
                </div>
              </div>
            </ReactWOW>
          </div>
        </div>
        <div className="about-right-bottom">
          <div className="about-bottom-img">
            <img src="assets/img/bg/03.jpg" alt="" />
          </div>
        </div>
      </section>
    );
  }
}

export default About;
