import classNames from 'classnames';
import moment from 'moment';
import React, { Component, createRef } from 'react';
import { Alert, Nav, Spinner, Tab } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Headerfour from '../layouts/AltHeaderComponent';
import Footer from '../layouts/FooterComponent';
import BookingDetails from '../sections/bookings/BookingDetails';
import BookingSummary from '../sections/bookings/BookingSummary';
import Payments from '../sections/bookings/Payments';
import SelectExtras from '../sections/extras/SelectExtras';
import BookingForm from '../sections/home/Bookingform';

import { Link } from 'react-router-dom';
import { getAddOns } from '../api/services/addon.service';
import { addBooking, checkAvailability } from '../api/services/booking.service';
import { checkVoucherValidation } from '../api/services/voucher.service';
import { BOOKING_TYPE, EXTRA_PAYMENT_TYPE } from '../constants/Package';

const STEPS = new Map([
  [0, 'extras'],
  [1, 'bookingDetails'],
  [2, 'bookingSummary']
]);

class BookingPage extends Component {
  constructor(props) {
    super(props);

    if (props.history.location.state) {
      const { state } = props.location;
      if (state.dateTime)
        this.checkAlternativeTimes(state.dateTime, state.selectedSubPackage);
    }

    this.state = {
      selectedAlternate: undefined,
      selectedStep: 0,

      isPayment: false,

      addOns: [],
      selectedAddons: [],

      fullName: '',
      emailAddress: '',
      phoneNumber: '',
      guestNumber: '',
      specialNote: '',
      reserverAddress: '',
      reserverCompanyName: '',
      city: '',
      place: '',
      dateTime: props.history.location.state
        ? props.history.location.state.dateTime
        : undefined,
      selectedPackage: props.history.location.state
        ? props.history.location.state.selectedPackage
        : undefined,
      selectedSubPackage: props.history.location.state
        ? props.history.location.state.selectedSubPackage
        : undefined,

      isLoading: false,

      availableSlots: [],
      isSlotAvailable: true,

      totalNetPrice: 0,
      totalPrice: 0,

      voucher: undefined,
      voucherValid: false,
      isVoucherValidationLoading: false,
      voucherValidationError: undefined,

      showAlert: false,

      booking: {},
      isFullyPaid: false,
      isBookingConfirmed: false
    };
  }

  async componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    this.setState({ isLoading: true });
    try {
      getAddOns().then(addOns => {
        this.setState({
          addOns,
          isLoading: false
        });
      });
    } catch (error) {
      this.setState({ isLoading: false });
      toast.error('Beim Abrufen von Extras ist ein Fehler aufgetreten');
    }
  }

  scrollRef = createRef();

  checkAlternativeTimes = (dateTime, selectedSubPackage) => {
    const endTime = moment(dateTime).add(selectedSubPackage.duration, 'minute');
    const date = moment(dateTime);
    this.setState({
      isLoading: true
    });
    try {
      setTimeout(async () => {
        const result = await checkAvailability(
          date.toISOString(),
          endTime.toISOString()
        );
        this.setState({
          isSlotAvailable: result?.isAvailable,
          availableSlots: result?.alternatives,
          isLoading: false,
          showAlert: true
        });

        window.scrollTo({
          top: 600,
          behavior: 'smooth'
        });
      }, 500);

      setTimeout(() => {
        this.setState({
          showAlert: false
        });
      }, 5000);
    } catch (error) {
      toast.error(
        'Bei der Überprüfung der verfügbaren Steckplätze ist ein Fehler aufgetreten'
      );
      this.setState({
        isLoading: false
      });
    }
  };

  handleExtrasNextClick = () => {
    const { selectedStep } = this.state;
    if (selectedStep >= 0 && selectedStep <= 2) {
      this.setState(
        {
          selectedStep: selectedStep + 1
        },
        () => this.handleScrollToTop()
      );
    }
  };

  handleBookingDetailsNextClick = (
    fullName,
    emailAddress,
    phoneNumber,
    guestNumber,
    specialNote,
    reserverAddress,
    reserverCompanyName,
    city,
    place
  ) => {
    const { selectedStep } = this.state;
    if (selectedStep >= 0 && selectedStep <= 2) {
      this.setState(
        {
          fullName,
          emailAddress,
          phoneNumber,
          guestNumber,
          specialNote,
          reserverAddress,
          reserverCompanyName,
          city,
          place,
          selectedStep: selectedStep + 1
        },
        () => {
          this.handleScrollToTop();
          this.calculateTotalPrice();
        }
      );
    }
  };

  handleBackPress = () => {
    const { selectedStep } = this.state;
    if (selectedStep >= 0) {
      this.setState(
        {
          selectedStep: selectedStep - 1
        },
        () => this.handleScrollToTop()
      );
    }
  };

  handleScrollToTop = () => {
    if (this.scrollRef.current) {
      this.scrollRef.current.scrollIntoView();
    }
  };

  handleAddonSelection = addonId => {
    const { selectedAddons } = this.state;
    if (selectedAddons.includes(addonId)) {
      this.setState(prevState => ({
        selectedAddons: prevState.selectedAddons.filter(id => id !== addonId)
      }));
    } else {
      this.setState({
        selectedAddons: selectedAddons.concat(addonId)
      });
    }
  };

  handleCheckAvailability = (dateTime, selectedPackage, selectedSubPackage) => {
    this.setState({
      dateTime,
      selectedPackage,
      selectedSubPackage,
      selectedStep: 0,
      isPayment: false,
      selectedAddons: []
    });

    this.checkAlternativeTimes(dateTime, selectedSubPackage);
  };

  validateVoucher = async voucherCode => {
    const { totalPrice } = this.state;
    try {
      this.setState({
        isVoucherValidationLoading: true,
        voucherValidationError: undefined
      });
      const result = await checkVoucherValidation(voucherCode);
      let updatedPrice = totalPrice;
      if (result?.amount && result.status === 'AVAILABLE') {
        updatedPrice = totalPrice - result.amount;
        this.setState({
          voucher: result,
          isVoucherValidationLoading: false,
          voucherValid: result.valid,
          totalPrice: result.valid && updatedPrice > 0 ? updatedPrice : 0
        });
      } else {
        let errorMessage = 'Der von Ihnen eingegebene Gutschein ist ungültig';
        if (result.status === 'CLOSED') {
          errorMessage =
            'Der von Ihnen eingegebene Gutschein wird bereits verwendet';
        } else if (result.status === 'NOTACTIVE') {
          errorMessage = 'Der von Ihnen eingegebene Gutschein ist ungültig';
        }
        this.setState({
          isVoucherValidationLoading: false,
          voucherValidationError: errorMessage,
          voucherValid: false
        });
      }
    } catch (error) {
      this.setState({
        isVoucherValidationLoading: false,
        voucherValid: false,
        voucherValidationError: 'Es ist ein Belegvalidierungsfehler aufgetreten'
      });
    }
  };

  handleVoucherCancel = () => {
    const { totalNetPrice } = this.state;

    this.setState({
      totalPrice: Number(totalNetPrice).toFixed(2),
      voucher: undefined,
      voucherValid: false,
      voucherValidationError: undefined
    });
  };

  getAddons = () => {
    const { addOns, selectedAddons } = this.state;
    const extras = addOns.filter(f => selectedAddons.includes(f.id));
    return extras;
  };

  calculateTotalPrice = () => {
    const { selectedSubPackage, selectedAddons, guestNumber } = this.state;
    let subPackagePrice = selectedSubPackage.price;
    let totalAddonPrice = 0;

    if (selectedAddons.length > 0) {
      const selectedExtras = this.getAddons();
      selectedExtras.forEach(e => {
        if (e.pricingType === EXTRA_PAYMENT_TYPE.UNIT) {
          totalAddonPrice += e.price * guestNumber;
        } else {
          totalAddonPrice += e.price;
        }
      });
    }

    const totalNetPrice = subPackagePrice + totalAddonPrice;
    const total = Number(totalNetPrice);

    this.setState({
      totalNetPrice: total,
      totalPrice: total
    });
  };

  handleSubPackageChange = selectedSubPackage => {
    this.setState({
      selectedSubPackage
    });
  };

  handlePackageChange = selectedPackage => {
    this.setState({
      selectedPackage
    });
  };

  handleRenderPayments = () => {
    const {
      dateTime,
      selectedSubPackage,
      fullName,
      emailAddress,
      phoneNumber,
      selectedAddons,
      specialNote,
      guestNumber,
      totalPrice,
      reserverAddress,
      reserverCompanyName,
      city,
      place,
      voucher
    } = this.state;

    const selectedTime = new Date(dateTime).toISOString();

    const endTime = moment(selectedTime).add(
      selectedSubPackage.duration,
      'minute'
    );

    const bookingData = {
      bookingType: BOOKING_TYPE.GENERAL,
      startTime: selectedTime,
      endTime: endTime.toISOString(),
      numOfPacks: Number(guestNumber),
      subPackage: selectedSubPackage.id,
      extras: selectedAddons,
      totalPrice: Number(totalPrice),
      reserverName: fullName,
      reserverEmail: emailAddress,
      reserverTelephone: phoneNumber,
      bookingNote: specialNote,
      reserverAddress: `${reserverAddress}, ${place} ${city}`,
      reserverCompanyName: reserverCompanyName,
      city,
      place,
      voucherId: voucher ? voucher.id : undefined
    };

    if (totalPrice > 0) {
      this.setState(
        {
          booking: bookingData,
          isPayment: true
        },
        () => this.handleScrollToTop()
      );
    } else {
      this.setState({ isFullyPaid: true, isLoading: true });
      this.handleBookingCreate();
    }
  };

  handleBookingCreate = async () => {
    const {
      dateTime,
      selectedSubPackage,
      fullName,
      emailAddress,
      phoneNumber,
      selectedAddons,
      specialNote,
      guestNumber,
      totalPrice,
      reserverAddress,
      reserverCompanyName,
      city,
      place,
      voucher
    } = this.state;

    const selectedTime = new Date(dateTime).toISOString();

    const endTime = moment(selectedTime).add(
      selectedSubPackage.duration,
      'minute'
    );

    const bookingData = {
      bookingType: BOOKING_TYPE.GENERAL,
      startTime: selectedTime,
      endTime: endTime.toISOString(),
      numOfPacks: Number(guestNumber),
      subPackage: selectedSubPackage.id,
      extras: selectedAddons,
      totalPrice: Number(totalPrice),
      reserverName: fullName,
      reserverEmail: emailAddress,
      reserverTelephone: phoneNumber,
      bookingNote: specialNote,
      reserverAddress: `${reserverAddress}, ${place} ${city}`,
      reserverCompanyName: reserverCompanyName,
      city,
      place,
      voucherId: voucher ? voucher.id : undefined
    };
    try {
      const bookingResponse = await addBooking(bookingData);
      this.setState(
        {
          isBookingConfirmed: true,
          isLoading: false,
          booking: bookingResponse
        },
        () => this.handleScrollToTop()
      );
    } catch (error) {
      toast.error('Beim Hinzufügen einer Buchung ist ein Fehler aufgetreten');
      this.setState({
        isLoading: false
      });
    }
  };

  renderFullPaidVoucherConfirmation = () => {
    return (
      <div className="row">
        <figure className="mt-45 mb-45">
          <img src="assets/img/banner/booking/thankyou.jpg" alt="" />
        </figure>
        <div className="col-12 mt-4" style={{ padding: '0px' }}>
          <button type="submit" className="main-btn btn-filled">
            <Link style={{ color: '#ffffff' }} to="/">
              Home
            </Link>
          </button>
        </div>
      </div>
    );
  };

  renderTabContent = () => {
    const {
      selectedAddons,
      fullName,
      emailAddress,
      phoneNumber,
      guestNumber,
      specialNote,
      selectedPackage,
      selectedSubPackage,
      dateTime,
      totalPrice,
      reserverAddress,
      reserverCompanyName,
      city,
      place,
      totalNetPrice,
      voucher,
      voucherValid,
      isVoucherValidationLoading,
      voucherValidationError
    } = this.state;

    const isNextDisabled =
      dateTime === undefined ||
      selectedPackage?.id === undefined ||
      selectedSubPackage?.id === undefined;

    return (
      <>
        <Nav
          variant="tabs"
          className="justify-content-center justify-content-lg-start"
        >
          {/*====== BOOKING EXTRAS TAB ITEM ======*/}
          <Nav.Item>
            <Nav.Link eventKey="extras">
              {' '}
              <i className="fas fa-puzzle-piece" /> Extras auswählen
            </Nav.Link>
          </Nav.Item>
          {/*====== BOOKING DETAILS TAB ITEM ======*/}
          <Nav.Item>
            <Nav.Link eventKey="bookingDetails">
              <i className="fas fa-plus-square" /> Buchungsdetails
            </Nav.Link>
          </Nav.Item>
          {/*====== BOOKING SUMMARY TAB ITEM ======*/}
          <Nav.Item>
            <Nav.Link eventKey="bookingSummary">
              <i className="fas fa-check-circle" /> Zusammenfassung
            </Nav.Link>
          </Nav.Item>
        </Nav>
        {/*====== BOOKING STEPPER CONTENT START ======*/}
        <Tab.Content className="mt-65">
          {/*====== BOOKING EXTRAS TAB CONTENT START ======*/}
          <Tab.Pane eventKey="extras">
            <form action="#">
              <div className="row">
                <SelectExtras
                  addOns={selectedSubPackage.addOns}
                  selectedAddons={selectedAddons}
                  onClickAddOn={this.handleAddonSelection}
                />
              </div>
            </form>
            <div className="row mt-30 justify-content-end">
              <div className="next-button col-lg-4 col-md-6">
                <button
                  className={classNames('availability-button', {
                    disabled: isNextDisabled
                  })}
                  onClick={this.handleExtrasNextClick}
                >
                  Nächste <i className="fal fa-arrow-right" />
                </button>
              </div>
            </div>
          </Tab.Pane>
          {/*====== BOOKING EXTRAS TAB CONTENT END ======*/}
          {/*====== BOOKING DETAILS TAB CONTENT START ======*/}
          <Tab.Pane eventKey="bookingDetails">
            <BookingDetails
              onBackPress={this.handleBackPress}
              onBookingDetailsNextClick={this.handleBookingDetailsNextClick}
              fullName={fullName}
              emailAddress={emailAddress}
              phoneNumber={phoneNumber}
              guestNumber={guestNumber}
              specialNote={specialNote}
              reserverCompanyName={reserverCompanyName}
            />
          </Tab.Pane>
          {/*====== BOOKING DETAILS TAB CONTENT END ======*/}
          {/*====== BOOKING SUMMARY TAB CONTENT START ======*/}
          <Tab.Pane eventKey="bookingSummary">
            <BookingSummary
              onBackPress={this.handleBackPress}
              renderPayments={this.handleRenderPayments}
              onValidateVoucher={this.validateVoucher}
              fullName={fullName}
              emailAddress={emailAddress}
              phoneNumber={phoneNumber}
              guestNumber={guestNumber}
              specialNote={specialNote}
              selectedPackage={selectedPackage}
              selectedSubPackage={selectedSubPackage}
              dateTime={dateTime}
              addons={this.getAddons()}
              totalNetPrice={totalNetPrice}
              totalPrice={totalPrice}
              reserverAddress={reserverAddress}
              reserverCompanyName={reserverCompanyName}
              city={city}
              place={place}
              voucherValid={voucherValid}
              voucher={voucher}
              isVoucherValidationLoading={isVoucherValidationLoading}
              voucherValidationError={voucherValidationError}
              onCancelVoucher={this.handleVoucherCancel}
            />
          </Tab.Pane>
          {/*====== BOOKING SUMMARY TAB CONTENT END ======*/}
        </Tab.Content>
        {/*====== BOOKING STEPPER CONTENT END ======*/}
      </>
    );
  };

  renderSuccessMessage = () => {
    return (
      <Alert variant="success">
        Glückwunsch! Der von Ihnen gewählte Termin ist noch frei. Wählen Sie nun
        Ihre Extras aus.
      </Alert>
    );
  };

  render() {
    const {
      selectedAlternate,
      selectedStep,
      isPayment,
      isLoading,
      availableSlots,
      isSlotAvailable,
      selectedSubPackage,
      selectedPackage,
      dateTime,
      showAlert,
      booking,
      isBookingConfirmed
    } = this.state;

    const disable = false;

    return (
      <div>
        <Headerfour page_title="Buchungsseite" router_link="Themen" />
        <BookingForm
          history={this.props.history}
          date={dateTime}
          onCheckAvailability={this.handleCheckAvailability}
          onSelectSubPackage={this.handleSubPackageChange}
          onSelectPackage={this.handlePackageChange}
        />
        {disable && (
          <section className="counter-section mt-30 mb-30">
            <div className="container">
              <div className="counter-inner bg-black pt-100 pb-60 pr-60 pl-60">
                <h2 className="label" style={{ textAlign: 'center' }}>
                  Die Ms Sissi kann aufgrund der aktuellen <b>COVID</b>{' '}
                  Situation derzeit leider nicht gebucht werden. Wir informieren
                  Sie, wenn es wieder losgeht
                </h2>
              </div>
            </div>
          </section>
        )}
        <section
          style={{
            pointerEvents: disable ? 'none' : 'auto',
            opacity: disable ? '50%' : '100%'
          }}
          className="contact-part pb-45"
        >
          {/* AVAILABLE TIME SLOTS */}
          {!isPayment && !isSlotAvailable && (
            <div
              className="available-slots"
              style={{
                pointerEvents: disable ? 'none' : 'auto',
                opacity: disable ? '50%' : '100%'
              }}
            >
              <div className="container">
                <div className="contact-form">
                  <>
                    <p>
                      Es tut uns leid. Unser Schiff ist zu Ihrem gewählten
                      Zeitpunkt leider nicht verfügbar. Wie wäre es mit einem
                      der folgenden Zeitpunkte?
                    </p>
                    <div>
                      <hr />
                      <br />
                    </div>
                    <div
                      className="row algin-items-center time-slots"
                      style={{ display: 'flex', flexDirection: 'row' }}
                    >
                      {availableSlots &&
                        availableSlots.map((t, index) => (
                          <div key={index} className="col-md-3">
                            <div
                              className={classNames('time-item', {
                                active: index === selectedAlternate?.index
                              })}
                              onClick={() => {
                                this.setState(
                                  {
                                    selectedAlternate: { index, date: t.date },
                                    dateTime: new Date(t.date),
                                    selectedPackage,
                                    selectedSubPackage
                                  },
                                  () => {
                                    this.checkAlternativeTimes(
                                      t.date,
                                      selectedSubPackage
                                    );
                                  }
                                );
                              }}
                            >
                              <div>
                                <b>
                                  {moment(new Date(t.date)).format(
                                    'ddd, DD MMM YYYY'
                                  )}
                                </b>
                                <br />
                                {moment(new Date(t.date)).format('HH:mm')}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </>
                </div>
              </div>
            </div>
          )}
        </section>
        {/*====== BOOKING PART START ======*/}
        <section
          className="contact-part pb-115 pt-45"
          style={{
            pointerEvents: disable ? 'none' : 'auto',
            opacity: disable ? '50%' : '100%'
          }}
        >
          {isSlotAvailable &&
            selectedPackage !== undefined &&
            selectedSubPackage !== undefined &&
            dateTime !== undefined && (
              <Tab.Container
                defaultActiveKey={STEPS.get(0)}
                activeKey={STEPS.get(selectedStep)}
              >
                <div ref={this.scrollRef} />
                <div className="container">
                  {isSlotAvailable && showAlert && this.renderSuccessMessage()}
                  <div className="contact-form">
                    {/*====== BOOKING PAYMENT ======*/}
                    {isPayment ? (
                      <Payments
                        booking={booking}
                        voucher={this.state.voucher}
                      />
                    ) : isBookingConfirmed ? (
                      this.renderFullPaidVoucherConfirmation()
                    ) : (
                      this.renderTabContent()
                    )}
                  </div>
                </div>
              </Tab.Container>
            )}
        </section>
        {/*====== BOOKING PART END ======*/}
        <Footer />
        {isLoading && (
          <div className={'spinner-container full-container'}>
            <Spinner animation="border" variant="secondary" />
          </div>
        )}
      </div>
    );
  }
}

export default BookingPage;
