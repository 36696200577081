import React, { useState, useEffect } from 'react';
import { getMonth, getYear, addDays, addHours } from 'date-fns';
import DatePicker from 'react-datepicker';
import { isIOS } from 'react-device-detect';
import 'react-datepicker/dist/react-datepicker.css';

import { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';

registerLocale('de', de);

function CustomDateTimePicker(props) {
  const [date, setDate] = useState(props.selectedDate || null);

  useEffect(() => {
    if (props.selectedDate) setDate(props.selectedDate);
  }, [props.selectedDate]);

  const handleDateTimeChange = date => {
    //   let dateVal = date;
    //   const dateHours = new Date(date).getHours();
    //   if (dateHours === 0) dateVal = new Date(date).setHours(8, 0, 0);
    setDate(date);
    props.onDateChange(date);
  };

  const months = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
  ];

  const CustomInput = ({ value, onClick }) => {
    return (
      <input
        type="text"
        onClick={onClick}
        defaultValue={value}
        placeholder="Datum auswählen"
        readOnly={true}
        style={{ fontSize: isIOS ? '16px' : '' }}
      />
    );
  };

  return (
    <DatePicker
      selected={date}
      onChange={handleDateTimeChange}
      customInput={<CustomInput />}
      showTimeSelect
      dateFormat="dd.MM.yyyy HH:mm"
      placeholderText="Datum auswählen"
      minDate={addHours(new Date(), 48)}
      openToDate={addDays(new Date(), 2)}
      timeFormat="HH:mm"
      timeIntervals={15}
      popperPlacement={'bottom-start'}
      locale="de"
      // minTime={new Date(new Date().setHours(8, 0, 0))}
      // maxTime={new Date(new Date().setHours(20, 0, 0))}
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
      }) => {
        const renderMonthText = () => months[getMonth(date)];

        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '5px'
            }}
          >
            <button
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              type="button"
            >
              {'<'}
            </button>

            <div style={{ fontSize: '15px', fontWeight: 600 }}>
              {renderMonthText()} {getYear(date)}
            </div>

            <button
              type="button"
              onClick={() => {
                increaseMonth();
              }}
              disabled={nextMonthButtonDisabled}
            >
              {'>'}
            </button>
          </div>
        );
      }}
    />
  );
}

export default CustomDateTimePicker;
