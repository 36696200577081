import React, { Component } from 'react';
import Headerfour from '../layouts/AltHeaderComponent';
import Footer from '../layouts/FooterComponent';
import Extras from '../sections/extras/ExtrasList';

class Roomgrid extends Component {
  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  render() {
    return (
      <div>
        <Headerfour page_title="Extras" router_link="Extras"/>
        {/*====== MENU AREA START ======*/}
        <Extras />
        {/*====== MENU AREA END ======*/}
        <Footer />
      </div>
    );
  }
}

export default Roomgrid;
