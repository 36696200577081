/* eslint-disable no-useless-escape */
import React from 'react';

import { UtilService } from '../../utils/UtilService';

class BookingDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: props.fullName || '',
      emailAddress: props.emailAddress || '',
      phoneNumber: props.phoneNumber || '',
      guestNumber: props.guestNumber,
      specialNote: props.specialNote || '',
      reserverAddress: props.reserverAddress || '',
      reserverCompanyName: props.reserverCompanyName || '',
      place: props.place || '',
      city: props.city || '',

      errors: {},
      isValid: false
    };
  }

  validateValues = name => {
    const {
      fullName,
      emailAddress,
      guestNumber,
      reserverAddress,
      place,
      city
    } = this.state;

    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    const numberValidationRegex = /^[0-9]+$/;

    let errors = this.state.errors;

    switch (name) {
      case 'fullName':
        errors.fullName =
          fullName.length === 0 ? 'Vor- und Nachname ist erforderlich' : '';
        break;
      case 'emailAddress':
        errors.emailAddress = validEmailRegex.test(emailAddress)
          ? ''
          : 'E-Mail Adresse ist erforderlich';
        break;
      case 'guestNumber':
        errors.guestNumber = guestNumber.match(numberValidationRegex)
          ? guestNumber > 54
            ? 'Die maximale Gastzahl beträgt 54'
            : ''
          : 'Anzahl der Gäste ist eingeben';
        break;
      case 'reserverAddress':
        errors.reserverAddress =
          reserverAddress.length === 0 ? 'Adresse ist erforderlich' : '';
        break;
      case 'place':
        errors.place = place.length === 0 ? 'PLZ ist erforderlich' : '';
        break;
      case 'city':
        errors.city = city.length === 0 ? 'Ort ist erforderlich' : '';
        break;
      default:
        break;
    }

    const isValid = this.validateForm(errors);

    this.setState({
      errors,
      isValid
    });
  };

  handleInputBlur = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    const numberValidationRegex = /^[0-9]+$/;

    let errors = this.state.errors;

    switch (name) {
      case 'fullName':
        errors.fullName =
          value.length === 0 ? 'Vor- und Nachname ist erforderlich' : '';
        break;
      case 'emailAddress':
        errors.emailAddress = validEmailRegex.test(value)
          ? ''
          : 'E-Mail Adresse ist erforderlich';
        break;
      case 'guestNumber':
        errors.guestNumber = value.match(numberValidationRegex)
          ? value > 54
            ? 'Die maximale Gastzahl beträgt 54'
            : ''
          : 'Anzahl der Gäste ist eingeben';
        break;
      case 'reserverAddress':
        errors.reserverAddress =
          value.length === 0 ? 'Adresse ist erforderlich' : '';
        break;
      case 'place':
        errors.place = value.length === 0 ? 'PLZ ist erforderlich' : '';
        break;
      case 'city':
        errors.city = value.length === 0 ? 'Ort ist erforderlich' : '';
        break;
      default:
        break;
    }

    const isValid = this.validateForm(errors);

    this.setState({
      errors,
      isValid
    });
  };

  handleOnNextClick = async () => {
    const {
      fullName,
      emailAddress,
      guestNumber,
      reserverAddress,
      reserverCompanyName,
      city,
      place,
      isValid,
      phoneNumber,
      specialNote
    } = this.state;

    this.validateValues('fullName');
    this.validateValues('emailAddress');
    this.validateValues('guestNumber');
    this.validateValues('reserverAddress');
    this.validateValues('city');
    this.validateValues('place');

    if (!isValid) {
      return;
    }

    this.props.onBookingDetailsNextClick(
      fullName,
      emailAddress,
      phoneNumber,
      guestNumber,
      specialNote,
      reserverAddress,
      reserverCompanyName,
      city,
      place
    );
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  validateForm = errors => {
    let valid = true;
    Object.values(errors).forEach(val => val.length > 0 && (valid = false));
    return valid;
  };

  render() {
    const {
      fullName,
      emailAddress,
      guestNumber,
      phoneNumber,
      specialNote,
      reserverAddress,
      reserverCompanyName,
      city,
      place,
      errors,
      isValid
    } = this.state;
    return (
      <>
        <div className="row">
          <div className="col-md-6">
            <div className="input-group">
              <span className="icon">
                <i className="far fa-user" />
              </span>
              <input
                name="fullName"
                type="text"
                placeholder="* Vor- und Nachname"
                onChange={this.handleInputChange}
                onBlur={this.handleInputBlur}
                value={fullName}
                required
                // autoComplete="off"
              />
            </div>
            <span
              style={{ color: 'red', marginBottom: '5px', marginTop: '5px' }}
            >
              {!isValid && errors['fullName']}
            </span>
          </div>
          <div className="col-md-6">
            <div className="input-group">
              <span className="icon">
                <i className="far fa-envelope" />
              </span>
              <input
                name="emailAddress"
                type="email"
                placeholder="* Email"
                value={emailAddress}
                onChange={this.handleInputChange}
                onBlur={this.handleInputBlur}
                // autoComplete="off"
              />
            </div>
            <span
              style={{ color: 'red', marginBottom: '5px', marginTop: '5px' }}
            >
              {this.state.errors['emailAddress']}
            </span>
          </div>
          {/*** Company Name */}
          <div className="col-md-12">
            <div className="input-group mt-30">
              <span className="icon">
                <i className="far fa-building" />
              </span>
              <input
                name="reserverCompanyName"
                type="text"
                placeholder="Firma  (Optional)"
                value={reserverCompanyName}
                onChange={this.handleInputChange}
                onBlur={this.handleInputBlur}
                // autoComplete="off"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-group mt-30">
              <span className="icon">
                <i className="far fa-phone" />
              </span>
              <input
                name="phoneNumber"
                type="text"
                placeholder="Telefonnummer"
                value={phoneNumber}
                onChange={e => {
                  this.handleInputChange(e);
                }}
                onBlur={e => {
                  const valid = UtilService.validateContactNumber(
                    e.target.value
                  );

                  if (!valid) {
                    this.setState({
                      errors: {
                        ...errors,
                        phoneNumber: 'Bitte gültigen Telefonnummer eingeben'
                      }
                    });
                  } else {
                    this.setState({
                      errors: { ...errors, phoneNumber: '' }
                    });
                  }
                }}
                autoComplete="off"
              />
            </div>
            <span
              style={{ color: 'red', marginBottom: '5px', marginTop: '5px' }}
            >
              {this.state.errors['phoneNumber']}
            </span>
          </div>
          <div className="col-md-6">
            <div className="input-group mt-30">
              <span className="icon">
                <i className="far fa-users" />
              </span>
              <input
                name="guestNumber"
                type="number"
                placeholder="* Anzahl der Gäste"
                value={guestNumber}
                onChange={this.handleInputChange}
                onBlur={this.handleInputBlur}
                // autoComplete="off"
              />
            </div>
            <span
              style={{ color: 'red', marginBottom: '5px', marginTop: '5px' }}
            >
              {this.state.errors['guestNumber']}
            </span>
          </div>
          {/*** Address */}
          <div className="col-md-12">
            <div className="input-group mt-30">
              <span className="icon">
                <i className="flaticon-location-pin" />
              </span>
              <input
                name="reserverAddress"
                type="text"
                placeholder="* Adresse"
                value={reserverAddress}
                onChange={this.handleInputChange}
                onBlur={this.handleInputBlur}
                // autoComplete="off"
              />
            </div>
            <span
              style={{ color: 'red', marginBottom: '5px', marginTop: '5px' }}
            >
              {this.state.errors['reserverAddress']}
            </span>
          </div>
          {/** city */}
          <div className="col-md-6">
            <div className="input-group mt-30">
              <span className="icon">
                <i className="flaticon-location-pin" />
              </span>
              <input
                onChange={this.handleInputChange}
                name="city"
                type="text"
                placeholder="* Ort"
                value={city}
                onBlur={this.handleInputBlur}
              />
            </div>
            <span
              style={{
                color: 'red',
                marginBottom: '5px',
                marginTop: '5px'
              }}
            >
              {!isValid && errors['city']}
            </span>
          </div>
          {/** Place */}
          <div className="col-md-6">
            <div className="input-group mt-30">
              <span className="icon">
                <i className="flaticon-location-pin" />
              </span>
              <input
                onChange={this.handleInputChange}
                onBlur={this.handleInputBlur}
                name="place"
                type="number"
                placeholder="* PLZ"
                value={place}
              />
            </div>
            <span
              style={{
                color: 'red',
                marginBottom: '5px',
                marginTop: '5px'
              }}
            >
              {!isValid && errors['place']}
            </span>
          </div>
          <div className="col-12">
            <div className="input-group textarea mt-30">
              <span className="icon">
                <i className="far fa-pen" />
              </span>
              <textarea
                name="specialNote"
                type="text"
                placeholder="Kommentar"
                value={specialNote}
                onChange={this.handleInputChange}
                onBlur={this.handleInputBlur}
                // autoComplete="off"
              />
            </div>
            <div
              style={{ color: 'red', marginBottom: '5px', marginTop: '5px' }}
            >
              {errors['specialNote']}
            </div>
          </div>
        </div>
        <div className="row mt-30 justify-content-between">
          <div className="next-button col-lg-4 col-md-6 mb-10">
            <button
              className="availability-button"
              onClick={this.props.onBackPress}
            >
              <i className="fal fa-arrow-left" /> Zurück
            </button>
          </div>
          <div className="next-button col-lg-4 col-md-6 mb-10">
            <button
              // style={{
              //   opacity: !isValid ? '50%' : '100%',
              //   pointerEvents: !isValid ? 'none' : 'auto'
              // }}
              onClick={this.handleOnNextClick}
            >
              Nächste <i className="fal fa-arrow-right" />
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default BookingDetails;
