import React, { Component } from 'react';
import CustomDateTimePicker from '../date-time-picker/CustomDateTimePicker';
import moment from 'moment';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import { getPackages } from '../../api/services/package.service';

import { getSubPackageByPackageId } from '../../api/services/sub-package.service';
import { isIOS } from 'react-device-detect';

class BookingForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateTime: props.date || props.history.location.state?.dateTime || null,
      selectedPackage: props.history.location?.state?.selectedPackage || {},
      selectedSubPackage:
        props.history.location.state?.selectedSubPackage || {},

      packages: [{ id: 0, title: 'Auswählen Themen', selected: 'selected' }],
      subPackages: [
        { id: 0, title: 'Auswählen Erlebnisse', selected: 'selected' }
      ],

      isSubPackagesLoading: false
    };
  }

  async componentDidMount() {
    const { state } = this.props.history.location;

    try {
      const packages = await getPackages();

      // TODO: Package and sub package map
      // const packagesMap = new Map();

      // packages.map(async p => {
      //   const subPackages = await getSubPackageByPackageId(p.id);
      //   packagesMap.set(p.id, subPackages);
      // });

      // console.log(packagesMap);

      const alreadySelectedPackage = packages.find(
        p => p.id === state?.selectedPackage.id
      );

      const allPackages = [{ id: 0, title: 'Auswählen Themen' }, ...packages];
      this.setState(
        {
          packages: allPackages
        },
        () => {
          if (alreadySelectedPackage && alreadySelectedPackage.id !== 0) {
            this.fetchSubPackages(alreadySelectedPackage.id);
          }
        }
      );
    } catch (error) {
      toast.error('Beim Laden der Pakete ist ein Fehler aufgetreten');
    }
  }

  componentDidUpdate(prevProps) {
    const { date } = this.props;

    const currentDate = moment(prevProps.date).format('YYYY-MM-DD');
    const updatedDate = moment(date).format('YYYY-MM-DD');

    if (!moment(currentDate).isSame(moment(updatedDate))) {
      this.setState({
        dateTime: date
      });
    }
  }

  fetchSubPackages = async packageId => {
    const { state } = this.props.history.location;
    this.setState({ isSubPackagesLoading: true });
    try {
      const subPackages = await getSubPackageByPackageId(packageId);

      const alreadySelectedSubPackage = subPackages.find(
        p => p.id === state?.selectedSubPackage.id
      );
      const allSubPackages = [
        { id: 0, title: 'Auswählen Erlebnisse' },
        ...subPackages
      ];
      this.setState({
        subPackages: allSubPackages,
        selectedSubPackage: alreadySelectedSubPackage
          ? alreadySelectedSubPackage
          : allSubPackages[0],
        isSubPackagesLoading: false
      });
    } catch (error) {
      toast.error('Beim Laden von Unterpaketen ist ein Fehler aufgetreten');
      this.setState({
        isSubPackagesLoading: false
      });
    }
  };

  onChangePackage = event => {
    const { packages } = this.state;
    const { onSelectPackage } = this.props;

    const packageId = event.target.value;
    // eslint-disable-next-line eqeqeq
    const packageOption = packages.find(p => p.id == packageId);

    if (packageOption) {
      this.setState(
        {
          selectedPackage: { ...packageOption }
        },
        () => {
          if (packageId !== '0') {
            this.fetchSubPackages(packageId);
          } else {
            this.setState({
              subPackages: [{ id: 0, title: 'Auswählen Erlebnisse' }],
              selectedSubPackage: { id: 0, title: 'Auswählen Erlebnisse' }
            });
          }

          if (onSelectPackage) onSelectPackage({ ...packageOption });
        }
      );
    }
  };

  onChangeSubPackage = event => {
    const { subPackages } = this.state;
    const { onSelectSubPackage } = this.props;
    // eslint-disable-next-line eqeqeq
    const subPackage = subPackages.find(p => p.id == event.target.value);

    if (subPackage) {
      this.setState(
        {
          selectedSubPackage: { ...subPackage }
        },
        () => {
          if (onSelectSubPackage) onSelectSubPackage({ ...subPackage });
        }
      );
    }
  };

  renderOptions = packageOption => {
    return (
      <option
        key={packageOption.id}
        value={packageOption.id}
        selected={packageOption?.selected}
      >
        {packageOption.title}
      </option>
    );
  };

  render() {
    const {
      dateTime,
      selectedSubPackage,
      selectedPackage,
      packages,
      subPackages,
      isSubPackagesLoading
    } = this.state;

    const isButtonDisabled =
      dateTime === null ||
      selectedPackage.id === undefined ||
      selectedSubPackage?.id === undefined ||
      selectedSubPackage?.id === 0;

    return (
      <section className="booking-form">
        <div className="container">
          <div className="booking-form-inner">
            <form>
              <div className="row align-items-end">
                <div className="col-lg-3 col-md-6">
                  <div className="inputs-filed mt-30">
                    <label>Datum und Uhrzeit</label>
                    <div className="icon">
                      <i className="fal fa-calendar-alt" />
                    </div>
                    <CustomDateTimePicker
                      selectedDate={this.state.dateTime}
                      onDateChange={date => {
                        this.setState({
                          dateTime: date
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="inputs-filed mt-30">
                    <label>Themen</label>
                    <div className="nice-select">
                      <select
                        style={{ fontSize: isIOS ? '16px' : '' }}
                        onChange={this.onChangePackage}
                        value={this.state.selectedPackage?.id}
                      >
                        {packages.map(p => this.renderOptions(p))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="inputs-filed mt-30">
                    <label>Erlebnisse</label>
                    <div className="nice-select">
                      <select
                        disabled={isSubPackagesLoading}
                        style={{ fontSize: isIOS ? '16px' : '' }}
                        onChange={this.onChangeSubPackage}
                        value={this.state.selectedSubPackage?.id}
                      >
                        {subPackages.map(p => this.renderOptions(p))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="inputs-filed mt-30">
                    <button
                      type="button"
                      className={classNames('availability-button', {
                        disabled: isButtonDisabled
                      })}
                      onClick={() => {
                        const isInBookingPage =
                          window.location.pathname === '/booking';
                        if (!isInBookingPage) {
                          this.props.history.push('/booking', {
                            dateTime,
                            selectedPackage,
                            selectedSubPackage
                          });
                        }

                        if (this.props.onCheckAvailability) {
                          this.props.onCheckAvailability(
                            dateTime,
                            selectedPackage,
                            selectedSubPackage
                          );
                        }
                      }}
                    >
                      Verfügbarkeit prüfen
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}

export default BookingForm;
