import React, { Component } from 'react';
import Header from '../layouts/HeaderComponent';
import Footer from '../layouts/FooterComponent';
import Mainbanner from '../sections/home/Banner';
import Bookingform from '../sections/home/Bookingform';
import Textblock from '../sections/home/Textblock';
import Testimonial from '../sections/home/Testimonials';
import Instagram from '../sections/home/Instagram';
import FeaturePackages from '../sections/home/FeaturePackages';
import About from '../sections/home/About';
import { toast } from 'react-toastify';
import { getHomePage } from '../api/services/cms.service';
import { Loader } from '../common/Loader';

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      homePage: {},
      isLoading: true
    };
  }

  async componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    try {
      const homePages = await getHomePage();
      if (homePages && homePages.length > 0) {
        this.setState({
          homePage: homePages[0],
          isLoading: false
        });
      }
    } catch (error) {
      toast.error('Beim Laden der Galerie ist ein Fehler aufgetreten');
    }
  }

  render() {
    const { homePage, isLoading } = this.state;
    const {
      banner_image = '',
      card1_title = '',
      card1_description = '',
      card2_title = '',
      card2_description = '',
      card3_img = '',
      card4_img = '',
      aboutus_title = '',
      aboutus_description = '',
      danube_title = '',
      danube_description = '',
      danube_videolink = '',
      slider_galary = [],
      slider_URL = []
    } = homePage;
    return (
      <div>
        <Header />
        {/*====== BANNER PART START ======*/}
        <Mainbanner banner_image={banner_image} />
        {/*====== BANNER PART ENDS ======*/}
        {/*====== BOOKING FORM START ======*/}
        <Bookingform history={this.props.history} />
        {/*====== BOOKING FORM END ======*/}
        {/*====== ABOUT START ======*/}
        <About
          card1_title={card1_title}
          card1_description={card1_description}
          card2_title={card2_title}
          card2_description={card2_description}
          card3_img={card3_img}
          card4_img={card4_img}
          aboutus_title={aboutus_title}
          aboutus_description={aboutus_description}
        />
        {/*====== ABOUT END ======*/}
        {/*====== FEATURE ROOM START ======*/}
        <FeaturePackages />
        {/*====== FEATURE ROOM END ======*/}
        {/*====== TEXT BLOCK START ======*/}
        <Textblock
          danube_title={danube_title}
          danube_description={danube_description}
          danube_videolink={danube_videolink}
        />
        {/*====== TEXT BLOCK END ======*/}
        {/*====== TESTIMONIAL SLIDER START ======*/}
        <Testimonial />
        {/*====== TESTIMONIAL SLIDER END ======*/}

        {/*====== INSTAGRAM FEED PART START ======*/}
        <Instagram slider_images={slider_galary} slider_URL={slider_URL} />
        {/*====== INSTAGRAM FEED PART END ======*/}
        <Footer />
        {isLoading && <Loader />}
      </div>
    );
  }
}

export default HomePage;
