import React, { useState, useEffect } from 'react';
import { Card, Row, Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import StripePayment from './StripePayment';
import SepaPayment from './SepaPayment';
import InvoicePayment from './InvoiceComponent';

import {
  addBooking,
  getS3URLbyEntityId
} from '../../api/services/booking.service';

// Payment Method Images
import cardPaymentImage from '../../../assets/img/payment/card.png';
import sepaPaymentImage from '../../../assets/img/payment/sepa.png';
import invoicePaymentImage from '../../../assets/img/payment/invoice.png';
import voucherImage from '../../../assets/img/voucher/voucher.jpg';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addVoucher } from '../../api/services/voucher.service';

export const PAYMENT_METHODS = [
  {
    key: 'STRIPE',
    title: 'Kreditkarte',
    image: cardPaymentImage
  },
  {
    key: 'SEPA',
    title: 'Überweisung',
    image: sepaPaymentImage
  },
  {
    key: 'INVOICE',
    title: 'Rechnung',
    image: invoicePaymentImage
  }
];

const PAYMENT_ENTITY = {
  BOOKING: 'BOOKING',
  VOUCHER: 'VOUCHER'
};

const STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

const stripePromise = loadStripe(STRIPE_KEY);

function Payments(props) {
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHODS[0].key);
  const [isBookingConfirmed, setIsBookingConfirmed] = useState(false);
  const [invoiceURL, setInvoiceURL] = useState('');
  const [booking, setBooking] = useState();
  const [isFailedPayment, setIsFailedPayment] = useState(false);
  const [voucher, setVoucher] = useState();

  const [isInvoiceURLFetching, setIsInvoiceURLFetching] = useState(false);

  useEffect(() => {
    if (isBookingConfirmed && props.isVoucher) {
      setIsInvoiceURLFetching(true);
      setTimeout(() => {
        getS3URLbyEntityId(voucher?.id, PAYMENT_ENTITY.VOUCHER).then(res => {
          setIsInvoiceURLFetching(false);
          if (res.s3URL) {
            setInvoiceURL(res.s3URL);
          }
        });
      }, 10000);
    }
    return () => {};
  }, [isBookingConfirmed, props.isVoucher, voucher]);

  /*====== Booking create ======*/
  const handleBookingCreate = async () => {
    let bookingResponse = {};
    try {
      if (props.booking && !booking?.id) {
        bookingResponse = await addBooking(props.booking);

        setBooking(bookingResponse);
      } else {
        setIsFailedPayment(false);
      }
    } catch (error) {
      toast.error('Beim Hinzufügen einer Buchung ist ein Fehler aufgetreten');

      throw new Error();
    }
  };

  const handleVoucherCreate = async () => {
    try {
      if (props.isVoucher && !voucher?.id) {
        const result = await addVoucher(props.voucher);
        setVoucher(result);
      } else {
        setIsFailedPayment(false);
      }
    } catch (error) {
      toast.error('Beim Erstellen Ihres Gutscheins ist ein Fehler aufgetreten');

      throw new Error();
    }
  };

  const handleOnBookingConfirmation = url => {
    setIsBookingConfirmed(true);
    window.scrollTo({
      top: 600,
      behavior: 'smooth'
    });

    if (url) {
      setInvoiceURL(url);
    }
  };

  const handleDownloadInvoice = () => {
    window.open(invoiceURL, '_blank');
  };

  const handlePaymentFailure = () => {
    setIsFailedPayment(true);
  };

  const renderPaymentMethodCard = ({ key, title, image }) => {
    /*====== PAYMENT METHOD CARDS ======*/
    return (
      <Card
        key={key}
        style={{ marginLeft: '10px', marginBottom: '10px' }}
        className={classNames({
          selected: key === paymentMethod
        })}
        onClick={() => {
          setPaymentMethod(key);
        }}
      >
        <Card.Body>
          <Card.Img
            className="mb-2 text-muted"
            src={image}
            alt="Stripe image here"
          />
          <Card.Title>{title}</Card.Title>
        </Card.Body>
      </Card>
    );
  };

  const renderPaymentMethod = () => {
    /*====== PAYMENT METHOD FORMS ======*/
    if (paymentMethod === PAYMENT_METHODS[1].key) {
      return (
        <Elements stripe={stripePromise}>
          <SepaPayment
            bookingId={booking?.id}
            voucherId={voucher?.id}
            name={
              props.isVoucher
                ? props.voucher?.voucherPurchaserName
                : props.booking?.reserverName
            }
            email={
              props.isVoucher
                ? props.voucher?.voucherPurchaserEmail
                : props.booking?.reserverEmail
            }
            isVoucherPayment={props.isVoucher}
            isFailedPayment={isFailedPayment}
            onBookingConfirmation={handleOnBookingConfirmation}
            onBookingCreate={handleBookingCreate}
            onVoucherCreate={handleVoucherCreate}
            onPaymentFailure={handlePaymentFailure}
          />
        </Elements>
      );
    } else if (paymentMethod === PAYMENT_METHODS[2].key) {
      return (
        <InvoicePayment
          bookingId={booking?.id}
          voucherId={voucher?.id}
          isVoucherPayment={props.isVoucher}
          isFailedPayment={isFailedPayment}
          onBookingConfirmation={handleOnBookingConfirmation}
          onBookingCreate={handleBookingCreate}
          onVoucherCreate={handleVoucherCreate}
          onPaymentFailure={handlePaymentFailure}
        />
      );
    }

    return (
      <StripePayment
        bookingId={booking?.id}
        voucherId={voucher?.id}
        isVoucherPayment={props.isVoucher}
        isFailedPayment={isFailedPayment}
        onBookingConfirmation={handleOnBookingConfirmation}
        onBookingCreate={handleBookingCreate}
        onVoucherCreate={handleVoucherCreate}
        onPaymentFailure={handlePaymentFailure}
      />
    );
  };

  const renderDownloadButton = () => {
    const downloadText = props.isVoucher
      ? 'Gutschein herunterladen'
      : 'Download Rechnung';
    if (isInvoiceURLFetching) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spinner animation="border" variant="secondary" />
        </div>
      );
    } else {
      if (props.isVoucher) {
        return (
          <>
            <div className="col-12" style={{ padding: '0px' }}>
              <button
                type="submit"
                className="main-btn btn-filled"
                onClick={handleDownloadInvoice}
              >
                {downloadText}
              </button>
            </div>
            <div className="col-12 mt-4" style={{ padding: '0px' }}>
              <button type="submit" className="main-btn btn-filled">
                <Link style={{ color: '#ffffff' }} to="/">
                  Home
                </Link>
              </button>
            </div>
          </>
        );
      }
      return (
        <div className="col-12 mt-4" style={{ padding: '0px' }}>
          <button type="submit" className="main-btn btn-filled">
            <Link style={{ color: '#ffffff' }} to="/">
              Home
            </Link>
          </button>
        </div>
      );
    }
  };

  const renderThankYouBanner = () => {
    if (props.isVoucher) {
      const { code, value } = voucher;
      console.log(voucher);
      return (
        <div className="row">
          <div
            className="voucher-img-container"
            style={{
              position: 'relative',
              textAlign: 'center'
            }}
          >
            <h1
              className="voucher-amount"
              style={{ fontFamily: 'Archivo, sans-serif', fontWeight: '900' }}
            >
              {value}
            </h1>
            <h3
              className="voucher-code"
              style={{ fontFamily: 'Archivo, sans-serif', fontWeight: '900' }}
            >
              {code}
            </h3>
            <figure className="mt-45 mb-45" style={{ padding: '15px' }}>
              <img src={voucherImage} alt="" />
            </figure>
          </div>
          <div className="col-12">{renderDownloadButton()}</div>
        </div>
      );
    }

    return (
      <div className="row">
        <figure className="mt-45 mb-45">
          <img src="assets/img/banner/booking/thankyou.jpg" alt="" />
        </figure>
        {renderDownloadButton()}
      </div>
    );
  };

  const renderBookingConfirmation = () => {
    const confirmationTitle = props.isVoucher
      ? 'Gutscheinbestätigung'
      : 'Buchungsbestätigung';

    return (
      <>
        {/*====== BOOKING CONFIRMED ======*/}
        <div className="booking-confirmed">
          <h4>{confirmationTitle}</h4>
          <hr />
        </div>
        {renderThankYouBanner()}
      </>
    );
  };

  return (
    <div className="payment-details" tabIndex={0}>
      {/*====== PAYMENT DETAILS ======*/}
      {!isBookingConfirmed ? (
        <div>
          <div>
            <h4>Zahlung</h4>
            <hr />
            <br />
          </div>
          <div className="payment-methods">
            {/* Add Stripe, SEPA, invoice */}
            <p>Wählen Sie eine Zahlungsmethode aus</p>
            <br />

            <Row
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginRight: '0px'
              }}
            >
              {PAYMENT_METHODS.map(data => renderPaymentMethodCard(data))}
            </Row>
          </div>

          <div className="payment">{renderPaymentMethod()}</div>
        </div>
      ) : (
        renderBookingConfirmation()
      )}
    </div>
  );
}

export default Payments;
