import { ApiCall } from "../apiBase";

/********get all addons query********/
export async function getAddOns() {
  let addOns = [];

  const result = await ApiCall({
    method: "post",
    data: {
      query: `
        query getAddOns {
          getAddOns {
            id
            title
            image
            description
            pricingType
            price
          }
        }
      `,
    },
  });

  if (!result.data.errors && result.data.data.getAddOns) {
    addOns = result.data.data.getAddOns;
  }

  return addOns;
}

/********get a addon by it's id query********/
export async function getAddOnById(addOnId) {
  let addOnRes = null;

  const result = await ApiCall({
    method: "post",
    data: {
      query: `
        query getAddOnById($id: ID!) {
          getAddOn(id: $id) {
            id
            title
            image
            description
            pricingType
            price
          }
        }
      `,
      variables: {
        id: addOnId,
      },
    },
  });

  if (!result.data.errors && result.data.data.getAddOn) {
    addOnRes = result.data.data.getAddOn;
  }

  return addOnRes;
}

/********add a addon mutation********/
export async function addAddOn(newAddOn) {
  let addAddOnRes = null;
  let success = false

  const result = await ApiCall({
    method: "post",
    data: {
      query: `
          mutation addAddOn($input: AddOnInput!) {
            addAddOn(input: $input) {
                id
                title
                image
                description
                pricingType
                price
            }
          }
        `,
      variables: {
        input: {
          title: newAddOn.extraTitle,
          image: newAddOn.extraTitleImageURL,
          description: newAddOn.extraDescription,
          pricingType: newAddOn.extraPricingType,
          price: newAddOn.extraPrice,
        },
      },
    },
  });

  if (!result.data.errors && result.data.data.addAddOn) {
    addAddOnRes = result.data.data.addAddOn;
  }

  if (addAddOnRes) {
    success = true
  }

  return success;
}

/********update a addon mutation********/
export async function updateAddOn(extraId, updatedAddOn) {
  let updateAddOnRes = null;

  const result = await ApiCall({
    method: "post",
    data: {
      query: `
        mutation updateAddOn($id: ID!, $input: AddOnInput!) {
          updateAddOn(id: $id, input: $input) {
            id
            title
            image
            description
            pricingType
            price
          }
        }
      `,
      variables: {
        id: extraId,
        input: {
          title: updatedAddOn.extraTitle,
          image: updatedAddOn.extraTitleImageURL,
          description: updatedAddOn.extraDescription,
          pricingType: updatedAddOn.extraPricingType,
          price: updatedAddOn.extraPrice,
        },
      },
    },
  });

  if (!result.data.errors && result.data.data.updateAddOn) {
    updateAddOnRes = result.data.data.updateAddOn;
  }

  return updateAddOnRes;
}

/********delete a addon mutation********/
export async function deleteAddOn(addOnId) {
  let deleteAddOnRes = null;
  let success = false

  const result = await ApiCall({
    method: "post",
    data: {
      query: `
          mutation deleteAddOn($id: ID!) {
            deleteAddOn(id: $id) {
              id
              title
              image
              description
              pricingType
              price
            }
          }
        `,
      variables: {
        id: addOnId,
      },
    },
  });

  if (!result.data.errors && result.data.data.deleteAddOn) {
    deleteAddOnRes = result.data.data.deleteAddOn;
  }

  if (deleteAddOnRes) {
    success = true
  }

  return success;
}
