import { ApiCall } from '../apiBase';

/********get all bookings query********/
export async function getCafeOpeningHours(currentDate) {
  let openHours = [];

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
        query getCafeOpeningHours($date: String!) {
          getCafeOpeningHours(date: $date) {
            startTime
            endTime
          }
        }
      `,
      variables: {
        date: currentDate
      }
    }
  });

  if (!result.data.errors && result.data.data.getCafeOpeningHours) {
    openHours = result.data.data.getCafeOpeningHours;
  }

  return openHours;
}
