import { ApiCall } from '../apiBase';

/********get all packages query********/
export async function getPackages() {
  let packages = [];

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
        query getPackages {
          getPackages {
            id
            title
            titleImage
            description
          }
        }
      `
    }
  });

  if (!result.data.errors && result.data.data.getPackages) {
    packages = result.data.data.getPackages;
  }

  return packages;
}

/********get a package by it's id query********/
export async function getPackageById(packageId) {
  let packageRes = null;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
        query getPackageById($id: ID!) {
          getPackage(id: $id) {
            id
            title
            titleImage
            description
            longDescription
          }
        }
      `,
      variables: {
        id: packageId
      }
    }
  });

  if (!result.data.errors && result.data.data.getPackage) {
    packageRes = result.data.data.getPackage;
  }

  return packageRes;
}

/********update a packge mutation********/
export async function updatePackage(updatedPackage) {
  let updatePackageRes = null;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
        mutation updatePackage($id: ID!, $input: PackageInput!) {
          updatePackage(id: $id, input: $input) {
            id
            title
            titleImage
            description
            subPackages {
              id
            }
            addOns {
              id
            }
          }
        }
      `,
      variables: {
        id: updatedPackage.id,
        input: {
          title: updatedPackage.title,
          titleImage: updatedPackage.titleImage,
          description: updatedPackage.description,
          subPackages: updatedPackage.subPackages,
          addOns: updatedPackage.addOns
        }
      }
    }
  });

  if (!result.data.errors && result.data.data.updatePackage) {
    updatePackageRes = result.data.data.updatePackage;
  }

  return updatePackageRes;
}

/********delete a package mutation********/
export async function deletePackage(packageId) {
  let deletePackageRes = null;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
          mutation deletePackage($id: ID!) {
            deletePackage(id: $id) {
              id
              title
              titleImage
              description
              subPackages {
                id
              }
              addOns {
                id
              }
            }
          }
        `,
      variables: {
        id: packageId
      }
    }
  });

  if (!result.data.errors && result.data.data.deletePackage) {
    deletePackageRes = result.data.data.deletePackage;
  }

  return deletePackageRes;
}
