import { ApiCall } from '../apiBase';

/********get cafepage query********/
export async function getCafePage() {
  let cafePages = [];

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
        query getCafepage {
            getCafepage {
                id
                closeMessage
                title
                description
                titleImages
                slider_images
            }
        }
      `
    }
  });

  if (!result.data.errors && result.data.data.getCafepage) {
    cafePages = result.data.data.getCafepage;
  }

  return cafePages;
}

/********get gallerypage query********/
export async function getGalleryPage() {
  let galleryPages = [];

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
          query getGallerypage {
              getGallerypage {
                  id
                  gallery_images
              }
          }
        `
    }
  });

  if (!result.data.errors && result.data.data.getGallerypage) {
    galleryPages = result.data.data.getGallerypage;
  }

  return galleryPages;
}

/********get Generalpage query********/
export async function getGeneralDetails() {
  let generalPages = [];

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
          query getGeneralpage {
              getGeneralpage {
                  id
                  coverImage
                  headerImage
                  email
                  phone
                  address
                  footer_description
                  doc_imprint
                  doc_dataProtection
                  doc_termsAndConditions
              }
          }
        `
    }
  });

  if (!result.data.errors && result.data.data.getGeneralpage) {
    generalPages = result.data.data.getGeneralpage;
  }

  return generalPages;
}

/********getHomepage query********/
export async function getHomePage() {
  let homePages = [];

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
          query getHomepage {
              getHomepage {
                id
                banner_image
                danube_videolink
                danube_title
                danube_description
                aboutus_title
                aboutus_description
                card1_title
                card1_description
                card2_title
                card2_description
                card3_img
                card4_img
                slider_galary
                slider_URL
              }
          }
        `
    }
  });

  if (!result.data.errors && result.data.data.getHomepage) {
    homePages = result.data.data.getHomepage;
  }

  return homePages;
}

/********getTestimonials query********/
export async function getTestimonials() {
  let testimonials = [];

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
          query getTestimonials {
              getTestimonials {
                id
                name
                photo
                designation
                description
              }
          }
        `
    }
  });

  if (!result.data.errors && result.data.data.getTestimonials) {
    testimonials = result.data.data.getTestimonials;
  }

  return testimonials;
}
