import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AltHeaderComponent from '../layouts/AltHeaderComponent';
import Footer from '../layouts/FooterComponent';
import { Link } from 'react-router-dom';

import { getPackages } from '../api/services/package.service';
import PackageCard from '../sections/package/PackageCard';
import { UtilService } from '../utils/UtilService';

class PackagePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      packages: [],
      isLoading: true
    };
  }

  async componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    try {
      const packages = await getPackages();
      this.setState({
        packages,
        isLoading: false
      });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { packages, isLoading } = this.state;
    const { history } = this.props;
    return (
      <div>
        <AltHeaderComponent page_title="Themen" router_link="Themen" />
        {/*====== ROOM-GRIRD START ======*/}
        <section className="room-section room-grid-style pt-120 pb-120">
          <div className="container">
            <div className="row justify-content-center room-gird-loop">
              {packages.map((item, i) => {
                return i !== 6 ? (
                  <PackageCard key={i} history={history} item={item} />
                ) : (
                  <div key={i} className="col-lg-12 col-md-12 col-sm-12">
                    <div className="room-list-box mb-30">
                      <div className="row no-gutters justify-content-center">
                        <div className="col-lg-5 col-md-12">
                          <div className="room-img-wrap">
                            <div
                              onClick={() => {
                                history.push({
                                  pathname: '/package-details',
                                  state: item.id
                                });
                              }}
                              className="room-img"
                              style={{
                                backgroundImage: `url(${UtilService.encodeImageURI(
                                  item.titleImage
                                )})`
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-7 col-md-12">
                          <div className="room-desc">
                            <div className="row align-items-center justify-content-center">
                              <div className="col-sm-8">
                                <h4 className="title">{item.title}</h4>
                                <p>{item.description}</p>
                              </div>
                              <div className="col-sm-4">
                                <div className="text-sm-center">
                                  <Link
                                    to={{
                                      pathname: '/package-details',
                                      state: item.id
                                    }}
                                    className="book-btn"
                                  >
                                    {'Mehr anzeigen'.toUpperCase()}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        {/*====== ROOM-GRIRD END ======*/}
        {isLoading && (
          <div className={'spinner-container full-container'}>
            <Spinner animation="border" variant="secondary" />
          </div>
        )}
        <Footer />
      </div>
    );
  }
}

export default withRouter(PackagePage);
