import React from 'react';
import CheckoutForm from './CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(STRIPE_KEY);

const StripePayment = props => {
  return (
    <div className="stripe-payment">
      <Elements stripe={stripePromise}>
        <CheckoutForm
          voucherId={props.voucherId}
          bookingId={props.bookingId}
          isVoucherPayment={props.isVoucherPayment}
          isFailedPayment={props.isFailedPayment}
          onBookingConfirmation={props.onBookingConfirmation}
          onBookingCreate={props.onBookingCreate}
          onVoucherCreate={props.onVoucherCreate}
          onPaymentFailure={props.onPaymentFailure}
        />
      </Elements>
    </div>
  );
};

export default StripePayment;
