import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ToastContainer, toast } from 'react-toastify';

import Preloader from './components/layouts/Preloader';
import GalleryPage from './components/pages/GalleryPage';
import HomePage from './components/pages/HomePage';
import PackagesPage from './components/pages/PackagesPage';
import CafePage from './components/pages/CafePage';
import VouchersPage from './components/pages/VouchersPage';
import ExtrasPage from './components/pages/ExtrasPage';
import PackagedetailsPage from './components/pages/PackagedetailsPage';
import BookingPage from './components/pages/BookingPage';
import ImprintPage from './components/pages/ImprintPage';
// import TermsPage from './components/pages/TermsPage';

import 'react-toastify/dist/ReactToastify.css';

export const history = createBrowserHistory();

toast.configure();
function App() {
  return (
    <BrowserRouter history={history}>
      {<Preloader />}
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/cafeteria" component={CafePage} />
        <Route path="/packages" component={PackagesPage} />
        <Route path="/vouchers" component={VouchersPage} />
        <Route path="/gallery" component={GalleryPage} />
        <Route path="/extras" component={ExtrasPage} />
        <Route path="/package-details" component={PackagedetailsPage} />
        <Route path="/booking" component={BookingPage} />
        {/* <Route path="/terms" component={TermsPage} /> */}
        <Route path="/imprint" component={ImprintPage} />
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </BrowserRouter>
  );
}

export default App;
