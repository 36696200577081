import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import SRLWrapper from 'simple-react-lightbox';

// function SampleNextArrow(props) {
//   const { onClick } = props;
//   return (
//     <div className="slick-arrow next-arrow" onClick={onClick}>
//       <i className="fal fa-arrow-right"></i>
//     </div>
//   );
// }

// function SamplePrevArrow(props) {
//   const { onClick } = props;
//   return (
//     <div className="slick-arrow prev-arrow" onClick={onClick}>
//       <i className="fal fa-arrow-left"></i>
//     </div>
//   );
// }

class Menuarea extends Component {
  render() {
    // const settings = {
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   fade: false,
    //   infinite: true,
    //   autoplay: true,
    //   autoplaySpeed: 4000,
    //   arrows: false,
    //   dots: false,
    //   nextArrow: <SampleNextArrow />,
    //   prevArrow: <SamplePrevArrow />
    // };
    const gallerysettings = {
      slidesToShow: 3,
      slidesToScroll: 1,
      fade: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      arrows: false,
      dots: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };

    const { title, description, sliderImages } = this.props;

    return (
      <section className="menu-area bg-white menu-with-shape menu-nagative-gallery">
        <div className="container">
          <div>
            <h4>{title}</h4>
            <hr />
            <br />
          </div>
          <div className="row" style={{ margin: '0px' }}>
            <p>{description}</p>
          </div>
        </div>
        {/* Gallery */}
        <div className="gallery-wrap">
          <div className="container">
            <SRLWrapper>
              <Slider
                className="row gallery-slider text-center"
                {...gallerysettings}
              >
                {sliderImages.map((image, i) => (
                  <div key={i} className="col-lg-12">
                    <a
                      href={image}
                      className="gallery-popup"
                      data-attribute="SRL"
                    >
                      <img src={image} alt="" />
                    </a>
                  </div>
                ))}
              </Slider>
            </SRLWrapper>
          </div>
        </div>
        {/* Shape */}
        <div className="shape-two">
          <img src="assets/img/icon/6.png" alt="" />
        </div>
      </section>
    );
  }
}

export default Menuarea;
