import React, { Component } from 'react';
import Slider from 'react-slick';
import { toast } from 'react-toastify';
import { getTestimonials } from '../../api/services/cms.service';
import { Loader } from '../../common/Loader';

class Testmonials extends Component {
  constructor(props) {
    super(props);

    this.state = {
      testimonials: [],
      isLoading: true
    };
  }

  async componentDidMount() {
    try {
      const testimonials = await getTestimonials();
      if (testimonials && testimonials.length > 0) {
        this.setState({
          testimonials: testimonials,
          isLoading: false
        });
      }
    } catch (error) {
      toast.error('Beim Laden der Testimonials ist ein Fehler aufgetreten');
    }
  }

  render() {
    const { testimonials, isLoading } = this.state;
    const settings = {
      slidesToShow: 3,
      slidesToScroll: 1,
      fade: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      arrows: false,
      dots: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };
    return (
      <section className="testimonial-section pb-115 pt-115">
        <div className="container">
          <div className="section-title text-center mb-80">
            <span className="title-tag">testimonials</span>
            <h2>Feedback & Erlebnisse</h2>
          </div>
          {/* testimonials loop  */}
          <Slider className="row testimonial-slider" {...settings}>
            {testimonials.map((item, i) => (
              <div key={i} className="col-lg-12">
                <div className="testimonial-box">
                  <div className="client-img">
                    <img src={item.photo} alt="" />
                    <span className="check">
                      <i className="fal fa-check" />
                    </span>
                  </div>
                  <h3>{item.name}</h3>
                  <span className="clinet-post">{item.designation}</span>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </Slider>
          {isLoading && <Loader />}
        </div>
      </section>
    );
  }
}

export default Testmonials;
