import React, { Component } from 'react';
import Headerfour from '../layouts/AltHeaderComponent';
import Footer from '../layouts/FooterComponent';
import ReactWOW from 'react-wow';
import { toast } from 'react-toastify';
import { getGalleryPage } from '../api/services/cms.service';
import { Loader } from '../common/Loader';

class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      galleryPage: {},
      isLoading: true
    };
  }
  async componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    try {
      const galleryPages = await getGalleryPage();
      if (galleryPages && galleryPages.length > 0) {
        this.setState({
          galleryPage: galleryPages[0],
          isLoading: false
        });
      }
    } catch (error) {
      toast.error('Beim Laden der Galerie ist ein Fehler aufgetreten');
    }
  }
  render() {
    const { galleryPage, isLoading } = this.state;
    const { gallery_images = [] } = galleryPage;
    return (
      <div>
        <Headerfour page_title="Foto Galerie" router_link="Galerie" />
        {/*====== GALLERY START ======*/}
        <section className="gallery-wrappper pt-120 pb-120">
          <div className="container">
            <div className="gallery-loop">
              {gallery_images.map((imgSrc, i) => (
                <ReactWOW
                  key={i}
                  animation="fadeInUp"
                  data-wow-delay={`.${i + 3}s`}
                >
                  <div className="single-gallery-image">
                    <img src={imgSrc} alt="Images" />
                  </div>
                </ReactWOW>
              ))}
            </div>
          </div>
        </section>
        {/*====== GALLERY END ======*/}
        <Footer />
        {isLoading && <Loader />}
      </div>
    );
  }
}

export default Gallery;
