import { PhoneNumberUtil } from 'google-libphonenumber';

export const UtilService = {
  validateWholeNumber(num) {
    var result = num - Math.floor(num) !== 0;

    if (result) return false;
    else return true;
  },

  getLocalizedNumber(number) {
    if (this.validateWholeNumber(number)) {
      return `${number.toLocaleString('de-DE')},-`;
    }

    return `${number.toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}`;
  },

  encodeImageURI(url) {
    return encodeURI(url);
  },
  validateContactNumber: contactNumber => {
    const phoneNumberValidatingInstance = PhoneNumberUtil.getInstance();
    try {
      const phoneNumber = phoneNumberValidatingInstance.parseAndKeepRawInput(
        contactNumber,
        'AT'
      );

      if (phoneNumberValidatingInstance.isValidNumber(phoneNumber)) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  },
  constructGoogleMapUrl: address => {
    const googleMapURL = `https://maps.google.com/?q=${encodeURIComponent(
      address
    )}`;

    return googleMapURL;
  }
};
