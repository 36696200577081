export const EXTRA_PAYMENT_TYPE = {
  UNIT: 'UNIT',
  ENTIRE: 'ENTIRE'
};

export const DEFAULT_EXTRA_IMAGE =
  'https://s3.eu-central-1.amazonaws.com/ms-sissi.uploads/images/ms-sissi-extra-default.png';

export const BOOKING_TYPE = {
  GENERAL: 'GENERAL',
  OUT_OF_SERVICE: 'OUT_OF_SERVICE',
  LONG: 'LONG'
};

export const VOUCHER_AMOUNT = [
  { id: '0', value: 'Betrag auswählen', label: 'Betrag auswählen' },
  { id: '1', value: 50, label: 'EUR 50,-' },
  { id: '2', value: 150, label: 'EUR 150,-' },
  { id: '3', value: 250, label: 'EUR 250,-' },
  { id: '4', value: 350, label: 'EUR 350,-' },
  { id: '5', value: 450, label: 'EUR 450,-' },
  { id: '6', value: 'Anderer Betrag', label: 'Anderer Betrag' }
];
