import React, { useEffect, useState } from 'react';
import { sendInvoicePendingEmail } from '../../api/services/booking.service';
import { sendVoucherInvoicePendingEmail } from '../../api/services/voucher.service';

import Loader from '../../common/Loader';

function InvoicePayment(props) {
  const [isLoading, setIsLoading] = useState(false);
  const {
    bookingId,
    isVoucherPayment,
    voucherId,
    isFailedPayment,
    onBookingConfirmation,
    onBookingCreate,
    onVoucherCreate,
    onPaymentFailure
  } = props;

  // Trigger for payment when there is a bookingId or voucherId
  useEffect(() => {
    const fetchInvoiceURL = async () => {
      if (isVoucherPayment) {
        sendVoucherInvoicePendingEmail(voucherId)
          .then(response => {
            setIsLoading(false);
            onBookingConfirmation(response.downloadURL);
          })
          .catch(() => {
            onPaymentFailure();
          });
      } else {
        sendInvoicePendingEmail(bookingId)
          .then(response => {
            setIsLoading(false);
            onBookingConfirmation(response.downloadURL);
          })
          .catch(() => {
            onPaymentFailure();
          });
      }
    };
    if (bookingId && !isFailedPayment) {
      fetchInvoiceURL();
    }

    if (isVoucherPayment && voucherId && !isFailedPayment) {
      fetchInvoiceURL();
    }
  }, [
    bookingId,
    isFailedPayment,
    isVoucherPayment,
    onBookingConfirmation,
    onPaymentFailure,
    props,
    voucherId
  ]);

  const fetchInvoiceURL = async () => {
    setIsLoading(true);
    if (isVoucherPayment) {
      try {
        await onVoucherCreate();
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      try {
        await onBookingCreate();
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="col-12" style={{ padding: '0px' }}>
      <div className="body">
        <b>
          <p>Anweisungen:</p>
        </b>
        <p>
          Bei Zahlung auf Rechnung wird Ihre Buchung oder Ihr Gutscheinkauf erst
          nach Zahlungseingang gültig.
        </p>
        <br />
      </div>
      <button type="submit" onClick={fetchInvoiceURL}>
        Weiter
      </button>
      {isLoading && <Loader />}
    </div>
  );
}

export default InvoicePayment;
