import React, { Component } from 'react';
import classNames from 'classnames';
import ReactWOW from 'react-wow';
import { Card } from 'react-bootstrap';
import {
  DEFAULT_EXTRA_IMAGE,
  EXTRA_PAYMENT_TYPE
} from '../../constants/Package';
import { UtilService } from '../../utils/UtilService';
import { isMobile } from 'react-device-detect';

class Menu extends Component {
  checkSelected = id => {
    const { selectedAddons } = this.props;
    if (selectedAddons.length > 0 && selectedAddons.includes(id)) {
      return true;
    }

    return false;
  };

  renderAddonItem = item => {
    const { onClickAddOn } = this.props;
    const imageUrl = item.image || DEFAULT_EXTRA_IMAGE;

    const isUnitPrice = item.pricingType === EXTRA_PAYMENT_TYPE.UNIT;
    return (
      <ReactWOW key={item.id} animation="fadeInUp" data-wow-delay={item.image}>
        <div
          className={classNames('single-menu-box ', {
            selected: this.checkSelected(item.id)
          })}
          style={{ cursor: 'pointer' }}
          onClick={() => onClickAddOn(item.id)}
        >
          <div
            className="menu-img"
            style={{
              backgroundImage:
                'url(' + UtilService.encodeImageURI(imageUrl) + ')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: isMobile ? '100% 100%': '150px 150px',
              backgroundPosition: 'top',
              backgroundColor: '#fff'
            }}
          ></div>
          <div className="menu-desc">
            <h4>{item.title}</h4>
            <p>{item.description}</p>

            <p
              style={{
                fontSize: '26px',
                color: '#777',
                fontWeight: '600',
                fontFamily: 'Archivo, sans-serif'
              }}
            >
              <span style={{ fontSize: '14px', fontWeight: '600' }}>EUR</span>{' '}
              {UtilService.getLocalizedNumber(item.price)}{' '}
              <span style={{ fontSize: '14px' }}>
                {isUnitPrice && 'pro Person'}
              </span>
            </p>

            <div className="menu-link">
              <i className="fas fa-check-circle" />
            </div>
          </div>
        </div>
      </ReactWOW>
    );
  };

  renderAddons = () => {
    const { addOns } = this.props;

    if (addOns && addOns.length > 0) {
      const indexToSplit = addOns.length / 2;
      let addonsPart1 = addOns.slice(0, indexToSplit + 1);
      let addonsPart2 = addOns.slice(indexToSplit + 1);

      if (addOns.length === 2) {
        addonsPart1 = addOns.slice(0, indexToSplit);
        addonsPart2 = addOns.slice(indexToSplit);
      }

      return (
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-10">
            {addonsPart1.map(item => this.renderAddonItem(item))}
          </div>
          <div className="col-lg-6 col-md-10">
            {addonsPart2.map(item => this.renderAddonItem(item))}
          </div>
        </div>
      );
    }

    return (
      <Card style={{ marginBottom: '10px', borderColor: '#DC143C' }}>
        <Card.Body>
          <Card.Title style={{ fontSize: '16px', marginBottom: '0px' }}>
            Für dieses Unterpaket sind keine Extras verfügbar
          </Card.Title>
        </Card.Body>
      </Card>
    );
  };

  render() {
    return (
      <section className="menu-area">
        <div className="container">
          {/* Menu Looop */}
          <div className="menu-loop">{this.renderAddons()}</div>
        </div>
      </section>
    );
  }
}

export default Menu;
