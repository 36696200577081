import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';

export class Loader extends Component {
  render() {
    return (
      <div className={'spinner-container full-container'}>
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }
}

export default Loader;
