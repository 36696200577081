import React, { useEffect, useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Alert } from 'react-bootstrap';

import { createPaymentIntent } from '../../api/services/payment.service';
import { getS3URLbyEntityId } from '../../api/services/booking.service';
import Loader from '../../common/Loader';

const CheckoutForm = props => {
  const [paymentError, setPaymentError] = useState('');
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const {
    bookingId,
    isVoucherPayment,
    onPaymentFailure,
    isFailedPayment,
    voucherId,
    onBookingConfirmation,
    onVoucherCreate
  } = props;

  const PAYMENT_ENTITY = {
    BOOKING: 'BOOKING',
    VOUCHER: 'VOUCHER'
  };

  useEffect(() => {
    const handlePaymentProcess = async () => {
      const paymentEntity = isVoucherPayment
        ? PAYMENT_ENTITY.VOUCHER
        : PAYMENT_ENTITY.BOOKING;

      try {
        const entityId = isVoucherPayment ? voucherId : bookingId;

        const clientSecretRes = await createPaymentIntent(
          entityId,
          paymentEntity,
          'CARD'
        );

        const result = await stripe.confirmCardPayment(
          clientSecretRes.clientSecret,
          {
            payment_method: {
              card: elements.getElement(CardElement)
            }
          }
        );

        if (result.error) {
          setIsPaymentLoading(false);
          onPaymentFailure();

          setPaymentError('Ihre Kartennummer ist unvollständig');
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
            if (isVoucherPayment) {
              // Voucher API call here
              getS3URLbyEntityId(voucherId, PAYMENT_ENTITY.VOUCHER).then(
                res => {
                  onBookingConfirmation(res.s3URL);

                  setIsPaymentLoading(false);
                }
              );
            } else {
              onBookingConfirmation();
            }
          }
        }
      } catch (error) {
        setIsPaymentLoading(false);
        setPaymentError('Zahlungsvorgang fehlgeschlagen');
        console.log(error);

        onPaymentFailure();
      }
    };

    if (bookingId && !isFailedPayment) {
      handlePaymentProcess();
    }

    if (isVoucherPayment && voucherId && !isFailedPayment) {
      handlePaymentProcess();
    }
  }, [
    PAYMENT_ENTITY.BOOKING,
    PAYMENT_ENTITY.VOUCHER,
    bookingId,
    elements,
    stripe,
    onPaymentFailure,
    isFailedPayment,
    isVoucherPayment,
    voucherId,
    onBookingConfirmation
  ]);

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    setIsPaymentLoading(true);

    if (isVoucherPayment) {
      try {
        await onVoucherCreate();
      } catch (error) {
        setIsPaymentLoading(false);
      }
    } else {
      try {
        await props.onBookingCreate();
      } catch (error) {
        setIsPaymentLoading(false);
      }
    }
  };

  const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
      base: {
        height: 70,
        backgroundColor: '#f8f8f8',
        paddingHorizontal: 40,
        iconColor: '#bead8e',
        color: '#777777',
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        '::placeholder': { color: '#777777' },
        marginBottom: '30px'
      }
    },
    hidePostalCode: true
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <p>Geben Sie Ihre Kreditkartendaten hier ein</p>
        <div className="body">
          <CardElement options={CARD_OPTIONS} />
        </div>
        <button type="submit" disabled={!stripe}>
          Jetzt bezahlen
        </button>
      </form>
      {paymentError && <Alert variant="danger">{paymentError}</Alert>}
      {isPaymentLoading && <Loader />}
    </>
  );
};

export default CheckoutForm;
