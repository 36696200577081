import React, { useEffect, useMemo, useState } from 'react';
import { useStripe, useElements, IbanElement } from '@stripe/react-stripe-js';

import { createPaymentIntent } from '../../api/services/payment.service';
import { getS3URLbyEntityId } from '../../api/services/booking.service';
import { Alert } from 'react-bootstrap';
import Loader from '../../common/Loader';

const useOptions = () => {
  const options = useMemo(
    () => ({
      supportedCountries: ['SEPA'],
      iconStyle: 'solid',
      placeholder: 'IBAN Number',
      placeholderCountry: 'AT',
      style: {
        base: {
          height: 70,
          backgroundColor: '#f8f8f8',
          paddingHorizontal: 40,
          iconColor: '#bead8e',
          color: '#777777',
          fontWeight: 500,
          fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          '::placeholder': { color: '#777777' }
        }
      }
    }),
    []
  );

  return options;
};

const SepaPayment = props => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const [paymentError, setPaymentError] = useState('');
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);

  const {
    bookingId,
    voucherId,
    onPaymentFailure,
    isVoucherPayment,
    name,
    email: EmailAddress,
    isFailedPayment,
    onBookingConfirmation,
    onVoucherCreate
  } = props;

  useEffect(() => {
    const PAYMENT_ENTITY = {
      BOOKING: 'BOOKING',
      VOUCHER: 'VOUCHER'
    };

    const handlePaymentProcess = async () => {
      const paymentEntity = isVoucherPayment
        ? PAYMENT_ENTITY.VOUCHER
        : PAYMENT_ENTITY.BOOKING;
      const entityId = isVoucherPayment ? props.voucherId : props.bookingId;

      try {
        const clientSecret = await createPaymentIntent(
          entityId,
          paymentEntity,
          'SEPA'
        );

        const iban = elements.getElement(IbanElement);

        const result = await stripe.confirmSepaDebitPayment(
          clientSecret.clientSecret,
          {
            payment_method: {
              sepa_debit: iban,
              billing_details: {
                name: name,
                email: EmailAddress
              }
            }
          }
        );

        if (result.error) {
          // Show error to your customer.
          setIsPaymentLoading(false);

          onPaymentFailure();

          setPaymentError('Dieses Konto kann keine Zahlungen erstellen.');
        } else {
          // Payment in processing state
          if (isVoucherPayment) {
            const invoiceS3URL = await getS3URLbyEntityId(
              props.voucherId,
              PAYMENT_ENTITY.VOUCHER
            );

            if (invoiceS3URL) {
              props.onBookingConfirmation(invoiceS3URL);
            }
            setIsPaymentLoading(false);
          } else {
            onBookingConfirmation();
            setIsPaymentLoading(false);
          }
        }
      } catch (error) {
        console.log(error);
        setIsPaymentLoading(false);
        onPaymentFailure();
        setPaymentError('Zahlungsvorgang fehlgeschlagen');
      }
    };

    if (bookingId && !isFailedPayment) {
      handlePaymentProcess();
    }

    if (isVoucherPayment && voucherId && !isFailedPayment) {
      handlePaymentProcess();
    }
  }, [
    bookingId,
    elements,
    stripe,
    onPaymentFailure,
    props,
    name,
    EmailAddress,
    isFailedPayment,
    onBookingConfirmation,
    isVoucherPayment,
    voucherId
  ]);

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setIsPaymentLoading(true);

    if (isVoucherPayment) {
      try {
        await onVoucherCreate();
      } catch (error) {
        setIsPaymentLoading(false);
      }
    } else {
      try {
        await props.onBookingCreate();
      } catch (error) {
        setIsPaymentLoading(false);
      }
    }
  };

  const renderCheckoutForm = () => {
    const isAvailable = true;
    return (
      <form onSubmit={handleSubmit} className="pb-20">
        <div className="body">
          <p>Geben Sie unten Ihre Kontodaten ein</p>
          <br />
          <IbanElement className="iban-element" options={options} />
        </div>
        {isAvailable ? (
          <button type="submit" disabled={!stripe}>
            Jetzt bezahlen
          </button>
        ) : (
          <div>
            <b>*Diese Zahlungsmethode steht derzeit nicht zu Verfügung.</b>
          </div>
        )}
      </form>
    );
  };

  return (
    <div className="sepa-payment">
      {renderCheckoutForm()}
      {paymentError && <Alert variant="danger">{paymentError}</Alert>}
      {isPaymentLoading && <Loader />}
    </div>
  );
};

export default SepaPayment;
