import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getGeneralDetails } from '../api/services/cms.service';
import { Loader } from '../common/Loader';
import { UtilService } from '../utils/UtilService';

class Footerthree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redText: false,
      generalDetails: {},
      isLoading: true
    };
  }
  async componentDidMount() {
    try {
      const generalDetails = await getGeneralDetails();
      if (generalDetails && generalDetails.length > 0) {
        this.setState({
          generalDetails: generalDetails[0],
          isLoading: false
        });
      }
    } catch (error) {
      toast.error('Beim Laden der Galerie ist ein Fehler aufgetreten');
    }

    window.addEventListener(
      'scroll',
      () => {
        this.setState({
          isTop: window.scrollY > 300
        });
      },
      false
    );
  }
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  render() {
    const className = this.state.isTop ? 'active' : '';
    const { generalDetails, isLoading } = this.state;
    const {
      email = '',
      phone = '',
      address = '',
      footer_description = '',
      doc_termsAndConditions = '',
      doc_dataProtection = '',
      doc_imprint = ''
    } = generalDetails;
    return (
      <div>
        {/*====== Back to Top ======*/}
        <Link
          to="#"
          className={`back-to-top ${className}`}
          id="backToTop"
          onClick={() => this.scrollToTop()}
        >
          <i className="fal fa-angle-double-up" />
        </Link>
        {/*====== FOOTER START ======*/}
        <footer className="footer-two">
          <div className="footer-widget-area light-theme pt-100 pb-50">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-12 col-sm-6 order-lg-1  order-md-1 order-sm-1">
                  {/* Site Info Widget */}
                  <div className="widget site-info-widget mb-50 col-md-12">
                    <div className="row">
                      <div className="footer-logo col-lg-6 col-md-3 mb-50">
                        <img src="assets/img/footer-ms-logo.png" alt="" />
                      </div>
                      <p className="col-lg-12 col-md-9">{footer_description}</p>
                    </div>
                    <div className="social-links mt-40">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/ReedereiRichtfeld/"
                      >
                        <i className="fab fa-facebook-f" />
                      </a>
                      {/* <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/ReedereiRichtfeld/"
                      >
                        <i className="fab fa-instagram" />
                      </a> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 order-lg-2 order-md-3 order-sm-2 mb-50">
                  {/* Nav Widget */}
                  <div className="widget nav-widget mb-50">
                    <div>
                      <ul>
                        <li>
                          <Link
                            onClick={() => this.scrollToTop()}
                            to="/packages"
                          >
                            Themen
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() => this.scrollToTop()}
                            to="/vouchers"
                          >
                            Gutschein
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() => this.scrollToTop()}
                            to="/cafeteria"
                          >
                            Donaucafe
                          </Link>
                        </li>
                        {/* <li>
                          <Link onClick={() => this.scrollToTop()} to="/extras">
                            Extras
                          </Link>
                        </li> */}
                        <li>
                          <Link
                            onClick={() => this.scrollToTop()}
                            to="/gallery"
                          >
                            Galerie
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6  order-md-2 order-sm-3 order-lg-3">
                  {/* Contact Widget */}
                  <div className="widget contact-widget">
                    <h4 className="widget-title">Kontakt</h4>
                    <div className="contact-lists">
                      <div className="contact-box">
                        <div className="icon">
                          <i className="flaticon-call" />
                        </div>
                        <div className="desc">
                          <h6 className="title">Telefon</h6>
                          {isMobile ? (
                            <a
                              style={{ textDecoration: 'none' }}
                              href={`tel:${phone}`}
                            >
                              {phone}
                            </a>
                          ) : (
                            <>{phone}</>
                          )}
                        </div>
                      </div>
                      <div className="contact-box">
                        <div className="icon">
                          <i className="flaticon-message" />
                        </div>
                        <div className="desc">
                          <h6 className="title">Email</h6>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="mailto:office@ms-sissi.at"
                          >
                            {email}
                          </a>
                        </div>
                      </div>
                      <div className="contact-box">
                        <div className="icon">
                          <i className="flaticon-location-pin" />
                        </div>
                        <div className="desc">
                          <h6 className="title">Adresse</h6>
                          <a
                            href={UtilService.constructGoogleMapUrl('MS Sissi')}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {address}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-area light-theme pt-30 pb-30">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-5 order-2 order-md-1">
                  <p className="copyright-text copyright-two">
                    &copy; Copyright 2021 Ms Sissi | made with{' '}
                    <span role="img" aria-label="">
                      ♥️
                    </span>{' '}
                    by{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://ausrilabs.at/"
                    >
                      AusriLabs
                    </a>
                  </p>
                </div>
                <div className="col-lg-6 col-md-7 order-1 order-md-2">
                  <div className="footer-menu text-center text-md-right">
                    <ul>
                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={doc_imprint}
                        >
                          Impressum
                        </a>
                        {/* <Link onClick={() => this.scrollToTop()} to="/imprint">
                          Impressum
                        </Link> */}
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={doc_dataProtection}
                        >
                          Datenschutz
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={doc_termsAndConditions}
                        >
                          AGB`s
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <script
                  id="CookieDeclaration"
                  src="https://consent.cookiebot.com/8c03bee1-ae87-413e-a32a-259b49a77d2c/cd.js"
                  type="text/javascript"
                  async
                ></script> */}
              </div>
            </div>
          </div>
        </footer>
        {/*====== FOOTER END ======*/}
        {isLoading && <Loader />}
      </div>
    );
  }
}

export default Footerthree;
