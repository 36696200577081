import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';

import HeaderComponent from '../layouts/AltHeaderComponent';
import Footer from '../layouts/FooterComponent';

import { getSubPackageByPackageId } from '../api/services/sub-package.service';

import { getPackageById } from '../api/services/package.service';
import Loader from '../common/Loader';
import { Card } from 'react-bootstrap';
import { UtilService } from '../utils/UtilService';

class PackageDetailsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subPackages: [],
      packageDetails: {},

      isLoading: true,
      selectedSubPackage: undefined
    };
  }

  scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  scrollToCalender(subPackageId) {
    const { subPackages, packageDetails } = this.state;

    this.setState({
      selectedSubPackage: subPackages.find(p => p.id === subPackageId)
    });

    this.props.history.push('/booking', {
      selectedPackage: packageDetails,
      selectedSubPackage: subPackages.find(p => p.id === subPackageId)
    });
  }

  async componentDidMount() {
    this.scrollToTop();
    try {
      const packageDetails = await getPackageById(this.props.location.state);
      this.setState({
        packageDetails
      });

      const subPackages = await getSubPackageByPackageId(
        this.props.location.state
      );
      this.setState({
        subPackages,
        isLoading: false
      });
    } catch (error) {}
  }

  renderSubPackages = () => {
    const { subPackages, isLoading } = this.state;
    if (subPackages.length > 0) {
      return (
        <div className="room-list-loop">
          {subPackages.map((item, i) => (
            <div key={item.id} className="room-box mb-30">
              <div className="row no-gutters justify-content-center">
                <div className="col-lg-5 col-md-10">
                  <div className="room-img-wrap">
                    <div
                      className="room-img"
                      style={{
                        backgroundImage:
                          'url(' +
                            UtilService.encodeImageURI(item.titleImage) ||
                          'https://s3.eu-central-1.amazonaws.com/ms-sissi.uploads/Subpackage-Default.jpg' +
                            ')'
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-7 col-md-10">
                  <div className="room-desc">
                    <div className="row align-items-center">
                      <div className="col-sm-7">
                        <h4 className="title">{item.title}</h4>
                        <p>{item.description}</p>
                      </div>
                      <div className="col-sm-5">
                        <div className="text-sm-center">
                          <span className="price">
                            {' '}
                            <span style={{ fontSize: '15px' }}> EUR</span>
                            {UtilService.getLocalizedNumber(item.price)}
                          </span>
                          <p> *inkl. MwSt.</p>
                        </div>
                        <br />
                        <div
                          className="main-btn btn-filled"
                          onClick={() => this.scrollToCalender(item.id)}
                        >
                          Jetzt Buchen
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }

    return (
      !isLoading && (
        <section className="room-section room-list-style pb-120">
          <div className="container">
            <Card style={{ marginBottom: '10px', borderColor: '#DC143C' }}>
              <Card.Body>
                <Card.Title style={{ fontSize: '16px', marginBottom: '0px' }}>
                  Für dieses Paket sind keine Unterpakete verfügbar
                </Card.Title>
              </Card.Body>
            </Card>
          </div>
        </section>
      )
    );
  };

  render() {
    const { packageDetails, isLoading } = this.state;
    return (
      <div>
        <HeaderComponent page_title="Erlebnisse" router_link="Erlebnisse" />
        {/*====== ROOM-DETAILS START ======*/}
        <section className="room-details pt-120 pb-90">
          <div className="container">
            <div className="row">
              {/* details */}
              <div className="col-lg-8">
                <div className="deatils-box">
                  <div className="title-wrap">
                    <div className="title">
                      <h2>{packageDetails.title}</h2>
                    </div>
                    <div className="price">{packageDetails.price}</div>
                  </div>
                  <div className="thumb" style={{ marginTop: '15px' }}>
                    <img src={packageDetails.titleImage} alt="" />
                  </div>
                </div>
              </div>
              {/* form */}
              <div className="col-lg-4">
                {!isLoading && packageDetails?.id && (
                  <section className="booking-form availability-form">
                    <div className="container">
                      {/* <div className="booking-form-inner"> */}
                      <p
                        style={{
                          marginBottom: '18px',
                          marginTop: isMobile ? '18px' : '155px'
                        }}
                      >
                        {packageDetails.longDescription}
                      </p>
                    </div>
                  </section>
                )}
              </div>
            </div>
          </div>
        </section>
        {/*====== ROOM-DETAILS END ======*/}
        {/*====== ROOM-LIST START ======*/}
        <section className="room-section room-list-style pb-120">
          <div className="container">
            <div>
              <h4>Erlebnisse</h4>
              <hr />
              <br />
            </div>
            {this.renderSubPackages()}
          </div>
        </section>

        {isLoading && <Loader />}
        {/*====== ROOM-DETAILS END ======*/}
        <Footer />
      </div>
    );
  }
}

export default PackageDetailsPage;
