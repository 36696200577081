import React, { useState, useEffect } from 'react';
import { Row, Form, Col, Spinner, Alert } from 'react-bootstrap';
import moment from 'moment';
import { UtilService } from '../../utils/UtilService';
import { EXTRA_PAYMENT_TYPE } from '../../constants/Package';

function BookingSummary(props) {
  const {
    fullName,
    emailAddress,
    phoneNumber,
    guestNumber,
    specialNote,
    selectedPackage,
    selectedSubPackage,
    dateTime,
    addons,
    totalPrice,
    reserverAddress,
    reserverCompanyName,
    city,
    place,
    totalNetPrice,
    voucherValid,
    voucher,
    isVoucherValidationLoading,
    voucherValidationError,
    onCancelVoucher
  } = props;

  const [voucherCode, setVoucherCode] = useState('');
  const [voucherChecked, setVoucherChecked] = useState(false);
  const mediaMatch = window.matchMedia('(min-width: 768px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  const dateAndTime = dateTime || new Date();

  const handleVoucherCancel = () => {
    onCancelVoucher();
  };

  const handleCheck = () => {
    setVoucherChecked(!voucherChecked);
  };

  const renderAddonBadges = () => {
    return addons.map(a => {
      const isUnitPrice = a.pricingType === EXTRA_PAYMENT_TYPE.UNIT;

      return (
        <div key={a.id} className="extraBadge mb-10">
          <span className="col-md-9 extraBadgeTitle">{a.title}</span>
          <span className="col-md-3 pl-5 extraBadgePrice">
            EUR
            <span
              style={{
                color: '#777',
                fontWeight: '600',
                fontSize: '24px',
                fontFamily: 'Archivo, sans-serif',
                marginLeft: '5px'
              }}
            >
              {isUnitPrice && (
                <> {UtilService.getLocalizedNumber(a.price * guestNumber)} </>
              )}
              {!isUnitPrice && <> {UtilService.getLocalizedNumber(a.price)} </>}
            </span>
          </span>
        </div>
      );
    });
  };

  const renderVoucherValidation = () => {
    // If the voucher validation is loading
    if (isVoucherValidationLoading) {
      return (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="secondary" />
        </div>
      );
    }

    if (voucherValid) {
      return (
        <div className="row d-flex mt-30">
          <div className="col-md-9">
            <h2 style={{ fontSize: '20px' }}>
              {' '}
              <span style={{ color: '#FF0000' }} onClick={handleVoucherCancel}>
                <i className="fas fa-times-circle" />
              </span>{' '}
              Gutscheinbetrag
            </h2>
          </div>
          <div className="col-md-3">
            <div style={{ fontSize: '24px' }}>
              <b>
                <span style={{ fontSize: '15px', marginLeft: '26px' }}>
                  {' '}
                  EUR
                </span>{' '}
                {UtilService.getLocalizedNumber(voucher.amount)}
              </b>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="mb-10">
          <div className="mb-10">
            <h5>Buchungszusammenfassung</h5>
          </div>
          {/* SELECTED PACKAGE START */}
          <Row>
            <Form.Label column sm="4">
              Themenfahrt
            </Form.Label>
            <Col
              sm="8"
              style={{
                display: 'flex',
                justifyContent: 'flex-start'
              }}
            >
              <Form.Label>{selectedPackage.title}</Form.Label>
            </Col>
          </Row>
          {/* SELECTED PACKAGE END */}
          {/* SELECTED SUB PACKAGE START */}
          <Row>
            <Form.Label column={true} sm="4">
              Ausgewähltes Erlebnisse
            </Form.Label>
            <Col
              sm="8"
              style={{
                display: 'flex',
                justifyContent: 'flex-start'
              }}
            >
              <Form.Label>{selectedSubPackage.title}</Form.Label>
            </Col>
          </Row>
          {/* SELECTED SUB PACKAGE END */}
          {/* SELECTED DATE START */}
          <Row>
            <Form.Label column={true} sm="4">
              Datum
            </Form.Label>
            <Col
              sm="8"
              style={{
                display: 'flex',
                justifyContent: 'flex-start'
              }}
            >
              <Form.Label>
                {moment(new Date(dateAndTime)).format('DD MMM YYYY')}
              </Form.Label>
            </Col>
          </Row>
          {/* SELECTED DATE END */}
          {/* SELECTED STARTING TIME START */}
          <Row>
            <Form.Label column={true} sm="4">
              Start
            </Form.Label>
            <Col
              sm="8"
              style={{
                display: 'flex',
                justifyContent: 'flex-start'
              }}
            >
              <Form.Label>
                {moment(new Date(dateAndTime)).format('HH:mm')}
              </Form.Label>
            </Col>
          </Row>
          {/* SELECTED  STARTING TIME END */}
          {/* SELECTED NUMBER OF PACKS START */}
          <Row>
            <Form.Label column={true} sm="4">
              Anzahl der Gäste
            </Form.Label>
            <Col
              sm="8"
              style={{
                display: 'flex',
                justifyContent: 'flex-start'
              }}
            >
              <Form.Label>{guestNumber}</Form.Label>
            </Col>
          </Row>
          {/* SELECTED  NUMBER OF PACKS END */}
        </div>
        <br />
        <hr />
        {/* ENTERED CONTACT DETAILS START */}
        <div>
          <div className="mb-10">
            <h5>Kontakt</h5>
          </div>
          {reserverCompanyName && <Row>
            <Form.Label column={true} sm="4">
              Firma
            </Form.Label>
            <Col
              sm="8"
              style={{
                display: 'flex',
                justifyContent: 'flex-start'
              }}
            >
              <Form.Label>{reserverCompanyName}</Form.Label>
            </Col>
          </Row>}
          {/* ENTERED COMPANY NAME END */}
          <Row>
            <Form.Label column={true} sm="4">
              Vor- und Nachname
            </Form.Label>
            <Col
              sm="8"
              style={{
                display: 'flex',
                justifyContent: 'flex-start'
              }}
            >
              <Form.Label>{fullName}</Form.Label>
            </Col>
          </Row>
          {/* ENTERED FULL NAME END */}
          {/* ENTERED EMAIL START */}
          <Row>
            <Form.Label column={true} sm="4">
              Email
            </Form.Label>
            <Col
              sm="8"
              style={{
                display: 'flex',
                justifyContent: 'flex-start'
              }}
            >
              <Form.Label>{emailAddress}</Form.Label>
            </Col>
          </Row>
          {/* ENTERED EMAIL END */}
          {/* ENTERED TELEPHONE START */}
          <Row>
            <Form.Label column={true} sm="4">
              Telefon
            </Form.Label>
            <Col
              sm="8"
              style={{
                display: 'flex',
                justifyContent: 'flex-start'
              }}
            >
              <Form.Label>{phoneNumber}</Form.Label>
            </Col>
          </Row>
          {/* ENTERED TELEPHONE END */}
          {/* ENTERED ADDRESS START */}
          <Row>
            <Form.Label column={true} sm="4">
              Addresse
            </Form.Label>
            <Col
              sm="8"
              style={{
                display: 'flex',
                justifyContent: 'flex-start'
              }}
            >
              <Form.Label>{`${reserverAddress}, ${place} ${city}`}</Form.Label>
            </Col>
          </Row>
          {/* ENTERED ADDRESS END */}
          {/* ENTERED MESSAGE START */}
          <Row>
            <Form.Label column={true} sm="4">
              Kommentar
            </Form.Label>
            <Col
              sm="8"
              style={{
                display: 'flex',
                justifyContent: 'flex-start'
              }}
            >
              <Form.Label>{specialNote}</Form.Label>
            </Col>
          </Row>
          {/* ENTERED MESSAGE END */}
          <br />
          <hr />
          <br />
        </div>
        {/* Subpackage */}
        <div>
          <div className="mb-10">
            <div>
              <h5 sm="4">Ausgewähltes Erlebnisse</h5>
            </div>
            <div className="mt-10">
              <span className="col-md-9 extraBadgeTitle">
                {selectedSubPackage.title}
              </span>
              <span className="col-md-3 pl-5 extraBadgePrice">
                EUR
                <span
                  style={{
                    color: '#777',
                    fontWeight: '600',
                    fontSize: '24px',
                    fontFamily: 'Archivo, sans-serif',
                    marginLeft: '5px'
                  }}
                >
                  {UtilService.getLocalizedNumber(selectedSubPackage.price)}
                </span>
              </span>
            </div>
          </div>
          <hr />
        </div>
        {/* Extras */}
        <div>
          {addons.length > 0 && (
            <>
              <div className="mb-10">
                <div>
                  <h5 column={true} sm="4">
                    Ausgewählte Extras
                  </h5>
                </div>
                <div className="mt-20">{renderAddonBadges()}</div>
              </div>
              <hr />
            </>
          )}
        </div>
        {/* Sub Amount */}
        <div className="row mt-30" style={{ alignItems: 'baseline' }}>
          <div className="col-md-9">
            <h2 style={{ fontSize: '20px' }}>Gesamt</h2>
          </div>
          <div className="col-md-3">
            <div style={{ fontSize: '20px' }}>
              <b>
                <span style={{ marginLeft: '26px', fontSize: '16px' }}>
                  {' '}
                  EUR
                </span>
                <span
                  style={{
                    color: '#777',
                    fontWeight: '600',
                    fontSize: '24px',
                    fontFamily: 'Archivo, sans-serif',
                    marginLeft: '5px'
                  }}
                >
                  {' '}
                  {UtilService.getLocalizedNumber(totalNetPrice)}{' '}
                </span>
              </b>
            </div>
          </div>
        </div>
        <hr />
        {/* Voucher Redem*/}
        <div>
          {!voucherValid && !isVoucherValidationLoading && (
            <Row style={{ margin: '0px' }}>
              <div style={{ width: '20px', height: '20px' }}>
                <input
                  style={{ height: '35px' }}
                  type="checkbox"
                  checked={voucherChecked}
                  onClick={handleCheck}
                />
              </div>
              <Form.Label
                column={true}
                sm="4"
                style={{ width: matches ? '100%' : '250px' }}
              >
                Gib den Gutschein-Code ein
              </Form.Label>
            </Row>
          )}
          {!voucherValid && !isVoucherValidationLoading && (
            <Row style={{ margin: '0px', marginBottom: '10px' }}>
              {voucherChecked && (
                <>
                  <div
                    style={{
                      paddingLeft: '0',
                      paddingRight: matches ? '10px' : '0'
                    }}
                    className="input-group mt-20 col-md-6"
                  >
                    <span
                      className="icon"
                      style={{ bottom: matches ? '40px' : '30px' }}
                    >
                      <i className="far fa-user" />
                    </span>
                    <input
                      name="voucherCode"
                      type="text"
                      placeholder="Gib den Gutschein-Code ein"
                      autoComplete="off"
                      value={voucherCode}
                      onChange={event => {
                        setVoucherCode(event.target.value);
                      }}
                      style={{ height: '60px' }}
                    />
                  </div>
                  <div
                    style={{
                      height: '70px',
                      paddingRight: '0',
                      paddingLeft: '0'
                    }}
                    className="col-md-6 mt-20"
                  >
                    <button
                      className=""
                      onClick={() => {
                        if (voucherCode !== '')
                          props.onValidateVoucher(voucherCode);
                      }}
                    >
                      Bestätigen <i className="fal fa-arrow-right" />
                    </button>
                  </div>
                </>
              )}
            </Row>
          )}
          {renderVoucherValidation()}
          {voucherValidationError && (
            <Alert variant="danger">{voucherValidationError}</Alert>
          )}
        </div>
        {/* Total Final Amount */}
        <hr />
        <div className="row mt-30" style={{ alignItems: 'baseline' }}>
          <div className="col-md-9">
            <h2 style={{ fontSize: '40px' }}>Gesamtbetrag inkl. Mwst.</h2>
          </div>
          <div className="col-md-3">
            <div style={{ fontSize: '40px' }}>
              <b>
                <span style={{ fontSize: '16px', marginLeft: '25px' }}>
                  {' '}
                  EUR
                </span>{' '}
                {UtilService.getLocalizedNumber(totalPrice)}
              </b>
            </div>
          </div>
        </div>
        <br />
        <div className="row mt-50 justify-content-between">
          <div className="next-button col-lg-4 col-md-4 mb-10">
            <button className="availability-button" onClick={props.onBackPress}>
              <i className="fal fa-arrow-left" /> Zukück
            </button>
          </div>
          <div className="next-button col-lg-6 col-md-8 mb-10">
            <button
              className="availability-button"
              onClick={props.renderPayments}
            >
              Zahlungspflichtig buchen <i className="fal fa-arrow-right" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingSummary;
