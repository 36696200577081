import { ApiCall } from '../apiBase';

/******* Upload Images to S3 and Get URL ******/
export async function createPaymentIntent(entityId, entityType, paymentType) {
  let signedURL = null;

  const result = await ApiCall({
    method: 'post',
    data: {
      query: `
        mutation createPaymentIntent($input: CreatePaymentIntentInput) {
          createPaymentIntent(input: $input) {
            clientSecret
          }
        }
      `,
      variables: {
        input: {
          entityId,
          entityType,
          paymentType
        }
      }
    }
  });

  if (!result.data.errors && result.data.data.createPaymentIntent) {
    signedURL = result.data.data.createPaymentIntent;
  }

  return signedURL;
}
