/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import ReactWOW from 'react-wow';
import TimeDisplay from './TimeDisplay';

class About extends Component {
  render() {
    const { openingHours, cafeCloseMsg, titleImages } = this.props;
    return (
      <section className="about-section pt-115 pb-115">
        <div className="container">
          <div className="down-arrow-wrap text-center">
            <TimeDisplay
              openingHours={openingHours}
              cafeCloseMsg={cafeCloseMsg}
            />
          </div>
          <div className="section-title about-title text-center mb-20">
            <span className="title-tag">Jetzt NEU</span>
            <h2>
              Erleben und genießen Sie die besondere Atmosphäre direkt auf der
              Donau
            </h2>
          </div>
          <ul className="about-features masonry-layout">
            {titleImages.map(function (image, i) {
              return (
                <ReactWOW
                  key={i}
                  animation="fadeInUp"
                  data-wow-delay={`.${i + 3}s`}
                >
                  <li>
                    <img src={image} />
                  </li>
                </ReactWOW>
              );
            })}
          </ul>
        </div>
      </section>
    );
  }
}

export default About;
