/* eslint-disable no-useless-escape */
import classNames from 'classnames';
import React, { Component } from 'react';

import Headerfour from '../layouts/AltHeaderComponent';
import Footer from '../layouts/FooterComponent';
//import { Link } from 'react-router-dom';
import Payments from '../sections/bookings/Payments';

import { Spinner } from 'react-bootstrap';
import { VOUCHER_AMOUNT } from '../constants/Package';
import { UtilService } from '../utils/UtilService';

class VouchersPage extends Component {
  constructor(props) {
    super(props);

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    this.state = {
      isPayments: false,
      amount: VOUCHER_AMOUNT[0],
      fullName: '',
      emailAddress: '',
      customAmount: 0,
      address: '',
      voucherId: '',
      city: '',
      place: '',
      isVoucherLoading: false,
      isCustomValue: false,
      voucherValue: 0,
      isValid: false,
      errors: {},
      voucher: {}
    };
  }

  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  calculateVoucherValue = () => {
    const { amount, customAmount } = this.state;

    let voucherAmount = amount.value;
    if (customAmount) {
      voucherAmount = Number(customAmount);
    }

    // Add VAT to voucher amount

    let voucherValue = Number(voucherAmount);
    voucherValue = voucherValue.toFixed();

    this.setState({
      voucherValue: isNaN(voucherValue) ? 0 : voucherValue
    });

    return { voucherValue };
  };

  handlePayments = () => {
    const {
      emailAddress,
      amount,
      fullName,
      customAmount,
      address,
      city,
      place,
      isValid
    } = this.state;

    this.validateValues('fullName');
    this.validateValues('emailAddress');
    this.validateValues('customAmount');
    this.validateValues('address');
    this.validateValues('city');
    this.validateValues('place');
    this.validateValues('amount');

    if (
      !isValid ||
      (amount.id !== '6' && isNaN(amount.value)) ||
      (customAmount && customAmount === 0)
    ) {
      return;
    }

    let voucherAmount = amount.value;
    if (customAmount) {
      voucherAmount = Number(customAmount);
    }

    // Add VAT to voucher amount
    let voucherValue = Number(voucherAmount);
    voucherValue = voucherValue.toFixed();

    const voucherDetails = {
      voucherValue: Number(voucherValue),
      voucherPurchaserEmail: emailAddress,
      voucherPurchaserName: fullName,
      purchaserAddress: `${address}, ${place} ${city}`
    };

    this.setState({
      isPayments: true,
      voucher: voucherDetails
    });

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  handleAmountSelect = event => {
    const { errors } = this.state;
    let value = VOUCHER_AMOUNT.find(a => a.id === event.target.value);

    const isCustomValue = value.id === '6';

    this.setState(
      {
        voucherValue:
          value.id === '0' || value.id === '6'
            ? 0
            : this.calculateVoucherValue().voucherValue,
        amount: { ...value },
        isCustomValue: isCustomValue,
        customAmount: 0,
        errors: !isCustomValue ? { ...errors, customAmount: '' } : errors
      },
      () => {
        this.calculateVoucherValue();
      }
    );
  };

  handleKeyupOnCustomAmount = () => {
    // To handle keyup situation
    this.calculateVoucherValue();
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  validateValues = name => {
    const {
      isCustomValue,
      fullName,
      address,
      customAmount,
      emailAddress,
      city,
      place,
      amount
    } = this.state;

    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    const numberValidationRegex = /^[0-9]+$/;

    let errors = this.state.errors;

    switch (name) {
      case 'fullName':
        errors.fullName =
          fullName.length === 0 ? 'Vor- und Nachname ist erforderlich' : '';
        break;
      case 'emailAddress':
        errors.emailAddress = validEmailRegex.test(emailAddress)
          ? ''
          : 'E-Mail Adresse ist erforderlich';
        break;
      case 'customAmount':
        errors.customAmount =
          isCustomValue &&
          customAmount &&
          customAmount.match(numberValidationRegex)
            ? //valid number
             ( 0 < customAmount && customAmount<= 1500)
              ? ''
              : 'Der Wert muss zwischen 0 und 1500 liegen'
            : 'Anzahl der Gäste ist eingeben';

        break;
      case 'address':
        errors.address = address.length === 0 ? 'Adresse ist erforderlich' : '';
        break;
      case 'city':
        errors.city = city.length === 0 ? 'Ort ist erforderlich' : '';
        break;
      case 'place':
        errors.place = place.length === 0 ? 'PLZ ist erforderlich' : '';
        break;
      case 'amount':
        errors.amount = amount.id === '0' ? 'Menge ist erforderlich' : '';
        break;
      default:
        break;
    }

    const isValid = this.validateForm(errors);
    this.setState({
      errors,
      isValid
    });
  };

  handleInputBlur = event => {
    const { isCustomValue } = this.state;
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    const numberValidationRegex = /^[0-9]+$/;

    let errors = this.state.errors;

    switch (name) {
      case 'fullName':
        errors.fullName =
          value.length === 0 ? 'Vor- und Nachname ist erforderlich' : '';
        break;
      case 'emailAddress':
        errors.emailAddress = validEmailRegex.test(value)
          ? ''
          : 'E-Mail Adresse ist erforderlich';
        break;

      case 'customAmount':
        errors.customAmount =
          isCustomValue && value && value.match(numberValidationRegex)
            ? (0 < value && value <= 1500)
              ? ''
              : 'Der Wert muss zwischen 0 und 1500 liegen'
            : 'Anzahl der Gäste ist eingeben';

        break;
      case 'address':
        errors.address = value.length === 0 ? 'Adresse ist erforderlich' : '';
        break;
      case 'city':
        errors.city = value.length === 0 ? 'Ort ist erforderlich' : '';
        break;
      case 'place':
        errors.place = value.length === 0 ? 'PLZ ist erforderlich' : '';
        break;
      case 'amount':
        errors.amount = value === 0 ? 'Menge ist erforderlich' : '';
        break;
      default:
        break;
    }

    const isValid = this.validateForm(errors);
    this.setState({
      errors,
      isValid
    });
  };

  validateForm = errors => {
    // const { isCustomValue } = this.state;
    let valid = true;
    Object.values(errors).forEach(val => val.length > 0 && (valid = false));
    return valid;
  };

  renderPaymentDetails = () => {
    const { voucher } = this.state;
    return <Payments isVoucher voucher={voucher} />;
  };

  renderVoucherAmountOptions = amount => {
    return (
      <option key={amount.id} value={amount.id}>
        {amount.label}
      </option>
    );
  };

  render() {
    const {
      isPayments,
      amount,
      isVoucherLoading,
      isValid,
      errors,
      customAmount,
      voucherValue,
      isCustomValue
    } = this.state;

    const disable = false;
    return (
      <div>
        <Headerfour page_title="Gutschein" router_link="Gutschein" />
        {/*====== BREADCRUMB PART START ======*/}

        {disable && (
          <section className="counter-section mt-30">
            <div className="container">
              <div className="counter-inner bg-black pt-100 pb-60 pr-60 pl-60">
                <h2 className="label" style={{ textAlign: 'center' }}>
                  Die Ms Sissi kann aufgrund der aktuellen <b>COVID</b>{' '}
                  Situation derzeit leider nicht gebucht werden. Wir informieren
                  Sie, wenn es wieder losgeht
                </h2>
              </div>
            </div>
          </section>
        )}
        {/*====== BREADCRUMB PART END ======*/}
        {/*====== CONTACT PART START ======*/}
        <section
          className="contact-part pt-115 pb-115"
          style={{
            pointerEvents: disable ? 'none' : 'auto',
            opacity: disable ? '50%' : '100%'
          }}
        >
          <div className="container">
            {/* Contact Info */}
            <div className="contact-info">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-sm-6 col-md-4 col-10">
                  <div className="info-box">
                    <div className="icon">
                      <i className="flaticon-shopping-cart" />
                    </div>
                    <div className="">
                      <h4>Schritt 1</h4>
                      <p>Gutschein auswählen</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-md-4 col-10">
                  <div className="info-box">
                    <div className="icon">
                      <i className="flaticon-credit-card" />
                    </div>
                    <div className="">
                      <h4>Schritt 2</h4>
                      <p>Zahlung durchführen</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-md-4 col-10">
                  <div className="info-box">
                    <div className="icon">
                      <i className="flaticon-download" />
                    </div>
                    <div className="">
                      <h4>Schritt 3</h4>
                      <p>Gutschein drucken</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="contact-form">
              {isPayments ? (
                this.renderPaymentDetails()
              ) : (
                <>
                  <div className="mb-50">
                    <img src="assets/img/sample-voucher.jpg" alt="voucher" />
                  </div>
                  <form action="#">
                    <div>
                      <h4>Gutschein auswählen</h4>
                      <hr />
                      <br />
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-group mt-30">
                          <span className="icon">
                            <i className="far fa-user" />
                          </span>
                          <input
                            onChange={this.handleInputChange}
                            onBlur={this.handleInputBlur}
                            name="fullName"
                            type="text"
                            placeholder="* Vor- und Nachname"
                            required
                          />
                        </div>
                        <span
                          style={{
                            color: 'red',
                            marginBottom: '5px',
                            marginTop: '5px'
                          }}
                        >
                          {!isValid && errors['fullName']}
                        </span>
                      </div>
                      <div className="col-md-6">
                        <div className="input-group mt-30">
                          <span className="icon">
                            <i className="far fa-envelope" />
                          </span>
                          <input
                            onChange={this.handleInputChange}
                            onBlur={this.handleInputBlur}
                            name="emailAddress"
                            type="email"
                            placeholder="* Email"
                            required
                          />
                        </div>
                        <span
                          style={{
                            color: 'red',
                            marginBottom: '5px',
                            marginTop: '5px'
                          }}
                        >
                          {!isValid && errors['emailAddress']}
                        </span>
                      </div>
                      {/** Address */}
                      <div className="col-md-12">
                        <div className="input-group mt-30">
                          <span className="icon">
                            <i className="flaticon-location-pin" />
                          </span>
                          <input
                            onChange={this.handleInputChange}
                            onBlur={this.handleInputBlur}
                            name="address"
                            type="text"
                            placeholder="* Adresse"
                            required
                          />
                        </div>
                        <span
                          style={{
                            color: 'red',
                            marginBottom: '5px',
                            marginTop: '5px'
                          }}
                        >
                          {!isValid && errors['address']}
                        </span>
                      </div>
                      {/** City */}
                      <div className="col-md-6">
                        <div className="input-group mt-30">
                          <span className="icon">
                            <i className="flaticon-location-pin" />
                          </span>
                          <input
                            onChange={this.handleInputChange}
                            onBlur={this.handleInputBlur}
                            name="city"
                            type="text"
                            placeholder="* Ort"
                            required
                          />
                        </div>
                        <span
                          style={{
                            color: 'red',
                            marginBottom: '5px',
                            marginTop: '5px'
                          }}
                        >
                          {!isValid && errors['city']}
                        </span>
                      </div>
                      {/** Place */}
                      <div className="col-md-6">
                        <div className="input-group mt-30">
                          <span className="icon">
                            <i className="flaticon-location-pin" />
                          </span>
                          <input
                            onChange={this.handleInputChange}
                            onBlur={this.handleInputBlur}
                            name="place"
                            type="number"
                            placeholder="* PLZ"
                            required
                          />
                        </div>
                        <span
                          style={{
                            color: 'red',
                            marginBottom: '5px',
                            marginTop: '5px'
                          }}
                        >
                          {!isValid && errors['place']}
                        </span>
                      </div>
                      {/* Amount Drop Down */}
                      <div className="col-md-6">
                        <div className="input-group mt-30">
                          <span className="icon">
                            <i className="far fa-book" />
                          </span>

                          <span className="suffix-icon">
                            <i className="far fa-angle-down"></i>
                          </span>
                          <select
                            name="amount"
                            onChange={this.handleAmountSelect}
                            onBlur={this.handleInputBlur}
                            value={this.state.amount.id}
                            style={{
                              '-webkit-appearance': 'none'
                            }}
                            required
                          >
                            {VOUCHER_AMOUNT.map(a =>
                              this.renderVoucherAmountOptions(a)
                            )}
                          </select>
                        </div>
                        <span
                          style={{
                            color: 'red',
                            marginBottom: '5px',
                            marginTop: '5px'
                          }}
                        >
                          {!isValid && errors['amount']}
                        </span>
                      </div>
                      {isCustomValue && (
                        <div className="col-md-6">
                          <div className="input-group mt-30">
                            <span className="icon">
                              <i className="far fa-money-bill" />
                            </span>
                            <input
                              onChange={this.handleInputChange}
                              onKeyUp={this.handleKeyupOnCustomAmount}
                              onBlur={this.handleInputBlur}
                              name="customAmount"
                              type="number"
                              autoComplete="off"
                              placeholder="Benutzerdefinierter Betrag"
                              required
                            />
                          </div>
                          <span
                            style={{
                              color: 'red',
                              marginBottom: '5px',
                              marginTop: '5px'
                            }}
                          >
                            {!isValid && errors['customAmount']}
                          </span>
                        </div>
                      )}
                    </div>
                  </form>
                  {/* <div className="row mt-30">
                      <div className="col-lg-4 col-md-6 col-sm-8">
                        <h2 style={{ fontSize: '20px' }}>
                          zzgl. Umsatzsteuer 20%
                      </h2>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div style={{ fontSize: '20px' }}>
                          <b>
                            <span style={{ fontSize: '15px' }}> EUR</span>{' '}
                            {amount || customAmount ? vatValue : 0}{' '}
                          </b>
                        </div>
                      </div>
                    </div> */}
                  <div className="row mt-50">
                    <div className="col-lg-9 col-md-9">
                      <h2 style={{ fontSize: '40px' }}>
                        Gesamtbetrag inkl. Mwst
                      </h2>
                    </div>
                    <div className="col-lg-3 col-md-3">
                      <div style={{ fontSize: '40px' }}>
                        <b>
                          <span style={{ fontSize: '15px' }}> EUR</span>{' '}
                          {amount || customAmount
                            ? UtilService.getLocalizedNumber(voucherValue)
                            : 0}{' '}
                        </b>
                      </div>
                    </div>
                  </div>

                  <div className="inputs-filed mt-30">
                    <button
                      className={classNames('availability-button', {
                        // disabled: !isValid
                      })}
                      // style={{
                      //   opacity: !isValid && '0.5',
                      //   pointerEvents: !isValid && 'none'
                      // }}
                      onClick={this.handlePayments}
                      // disabled={!isValid}
                    >
                      Zahlungspflichtig bestellen
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
        {/*====== CONTACT PART END ======*/}
        <Footer />
        {isVoucherLoading && (
          <div className={'spinner-container full-container'}>
            <Spinner animation="border" variant="secondary" />
          </div>
        )}
      </div>
    );
  }
}

export default VouchersPage;
