import React, { Component } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';

import Headerfour from '../layouts/AltHeaderComponent';
import Footer from '../layouts/FooterComponent';
//import { Link } from 'react-router-dom';
import { Loader } from '../common/Loader';
import About from '../sections/cafe/About';
import Menuarea from '../sections/cafe/Menuarea';
import { getCafeOpeningHours } from '../api/services/cafe.service';
import { getCafePage } from '../api/services/cms.service';

class CafePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openingHours: [],
      cafePage: {},
      isLoading: true
    };
  }
  async componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    try {
      const date = moment().format('YYYY-MM-DD');
      const openingHours = await getCafeOpeningHours(date);
      const cafePages = await getCafePage();
      if (cafePages && cafePages.length > 0) {
        this.setState({
          openingHours: openingHours,
          cafePage: cafePages[0],
          isLoading: false
        });
      }
    } catch (error) {
      toast.error('Beim Laden der Cafe-Zeit ist ein Fehler aufgetreten');
    }
  }

  render() {
    const { openingHours, cafePage, isLoading } = this.state;
    const {
      description = '',
      title = '',
      titleImages = [],
      slider_images = [],
      closeMessage = ''
    } = cafePage;

    return (
      <div>
        <Headerfour page_title="Donaucafe" router_link="Donaucafe" />

        <About
          openingHours={openingHours}
          cafeCloseMsg={closeMessage}
          titleImages={titleImages}
        />
        {/*====== ROOM Gallery CTA START ======*/}
        <Menuarea
          title={title}
          description={description}
          sliderImages={slider_images}
        />
        {/*====== ROOM Gallery CTA END ======*/}
        <Footer />
        {isLoading && <Loader />}
      </div>
    );
  }
}

export default CafePage;
