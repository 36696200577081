import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import Slider from 'react-slick';
import ReactWOW from 'react-wow';

import { getAddOns } from '../../api/services/addon.service';
import { UtilService } from '../../utils/UtilService';

class ExtrasList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      addOns: []
    };
  }

  async componentDidMount() {
    try {
      const addOns = await getAddOns();
      this.setState({
        addOns,
        isLoading: false
      });
    } catch (error) {
      this.setState({
        isLoading: false
      });
    }
  }

  renderExtras = () => {
    const { isLoading, addOns } = this.state;
    const indexToSplit = addOns.length / 2;
    const menuPosts1 = addOns.slice(0, indexToSplit + 1);
    const menuPosts2 = addOns.slice(indexToSplit + 1);

    const defaultExtraImage =
      'https://s3.eu-central-1.amazonaws.com/ms-sissi.uploads/Default-Extra.jpg';

    if (!isLoading && addOns && addOns.length > 0) {
      return (
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-10">
            {menuPosts1.map((item, i) => {
              const imageUrl = item.image || defaultExtraImage;
              return (
                <ReactWOW
                  key={item.id}
                  animation="fadeInUp"
                  data-wow-delay={item.menuanimation}
                >
                  <div className="single-menu-box hover-drak">
                    <div
                      className="menu-img"
                      style={{
                        backgroundImage:
                          'url(' + UtilService.encodeImageURI(imageUrl) + ')'
                      }}
                    ></div>
                    <div className="menu-desc">
                      <h4>
                        <Link to={item.url}>{item.title}</Link>
                      </h4>
                      <p>{item.description}</p>
                      <Link to={item.url} className="menu-link">
                        <i className="fal fa-long-arrow-right" />
                      </Link>
                    </div>
                  </div>
                </ReactWOW>
              );
            })}
          </div>
          <div className="col-lg-6 col-md-10">
            {menuPosts2.map((item, i) => {
              const imageUrl = item.image || defaultExtraImage;

              return (
                <ReactWOW
                  key={i}
                  animation="fadeInUp"
                  data-wow-delay={item.menuanimation}
                >
                  <div className="single-menu-box hover-drak">
                    <div
                      className="menu-img"
                      style={{
                        backgroundImage:
                          'url(' + UtilService.encodeImageURI(imageUrl) + ')'
                      }}
                    ></div>
                    <div className="menu-desc">
                      <h4>
                        <Link to={item.url}>{item.title}</Link>
                      </h4>
                      <p>{item.description}</p>
                      <Link to={item.url} className="menu-link">
                        <i className="fal fa-long-arrow-right" />
                      </Link>
                    </div>
                  </div>
                </ReactWOW>
              );
            })}
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <section className="menu-area pt-115 pb-115">
        <div className="container">
          {/* Menu Looop */}
          <div className="menu-loop">{this.renderExtras()}</div>
          {/* <div className="pagination-wrap">
            <ul>
              <li>
                <Link to="#">
                  <i className="far fa-angle-double-left" />
                </Link>
              </li>
              <li className="active">
                <Link to="#">1</Link>
              </li>
              <li>
                <Link to="#">2</Link>
              </li>
              <li>
                <Link to="#">3</Link>
              </li>
              <li>
                <Link to="#">...</Link>
              </li>
              <li>
                <Link to="#">10</Link>
              </li>
              <li>
                <Link to="#">
                  <i className="far fa-angle-double-right" />
                </Link>
              </li>
            </ul>
          </div> */}
        </div>
      </section>
    );
  }
}

export default ExtrasList;
